import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Flex, IconButton, Image, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import { Icon } from "@app/components/Icon"

import { ModalImage } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  isImageModalOpen: boolean
  setIsImageModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalImages: ModalImage[]
  setModalImages: React.Dispatch<React.SetStateAction<ModalImage[]>>
  isProductModalOpen: boolean
}

const PersonaliserImageLightbox: React.FC<Props> = ({
  isImageModalOpen,
  setIsImageModalOpen,
  modalImages,
  setModalImages,
  isProductModalOpen,
}) => {
  const { getGatsbyImage } = useImage()
  const formattedImages = useMemo(() => modalImages.map(img => getGatsbyImage(img)), [modalImages, getGatsbyImage])

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    setActiveIndex(0)
  }, [formattedImages])

  const onCloseHandler = useCallback(() => {
    setIsImageModalOpen(false)
    setModalImages([])
  }, [setIsImageModalOpen, setModalImages])

  const onNextClickHandler = useCallback(() => {
    const nextActiveIndex = formattedImages[activeIndex + 1] ? activeIndex + 1 : 0
    setActiveIndex(nextActiveIndex)
  }, [formattedImages, activeIndex, setActiveIndex])

  const onPrevClickHandler = useCallback(() => {
    const nextActiveIndex = formattedImages[activeIndex - 1] ? activeIndex - 1 : formattedImages.length - 1
    setActiveIndex(nextActiveIndex)
  }, [formattedImages, activeIndex, setActiveIndex])

  return formattedImages ? (
    <Modal isOpen={isImageModalOpen} onClose={onCloseHandler} size="full" blockScrollOnMount={!isProductModalOpen}>
      <ModalOverlay bg="background.white" />

      <IconButton
        onClick={onCloseHandler}
        variant="unstyled"
        icon={<Icon name="ui/close" width="24px" height="24px" />}
        aria-label={"Close Modal"}
        pos="fixed"
        top={{ base: "4", lg: "8" }}
        right={{ base: "4", lg: "8" }}
        bg="background.white"
        zIndex="popover"
        d="inline-flex"
        justifyContent="center"
        alignItems="center"
        w="10"
        h="10"
        borderRadius="full"
        boxShadow="subtleBottomGlow"
        _focus={{ boxShadow: "subtleBottomGlow" }}
      />

      <ModalContent maxH="100%">
        <ModalBody
          d="flex"
          flexDir="column"
          justifyContent={{ base: "center", lg: "stretch" }}
          alignItems="center"
          px={{ base: "3", md: "6" }}
          maxH="full"
        >
          <Flex
            flexDir="column"
            justifyContent="center"
            flex={{ lg: 1 }}
            bg="background.beige"
            w="full"
            py="19"
            px={{ base: "3", md: "6", lg: "13" }}
            maxW={{ base: "100", md: "125", lg: "160", xl: "215" }}
          >
            <Image {...formattedImages[activeIndex]} w="auto" maxH="80vh" alignSelf="center" justifySelf="center" />
          </Flex>
        </ModalBody>
      </ModalContent>

      {formattedImages.length > 1 ? (
        <Flex
          justifyContent={{ base: "center", lg: "space-between" }}
          gap={{ base: "5" }}
          pos="fixed"
          top={{ lg: "50%" }}
          bottom={{ base: "8", lg: "unset" }}
          left={{ base: "0", lg: "0" }}
          transform={{ lg: "translateY(-50%)" }}
          w="full"
          px={{ lg: "8" }}
          zIndex="popover"
        >
          <IconButton
            variant="unstyled"
            onClick={onPrevClickHandler}
            icon={<Icon name="arrows/chevron-left" width="24px" height="24px" />}
            aria-label={"Previous Image"}
            bg="background.white"
            zIndex="popover"
            d="inline-flex"
            justifyContent="center"
            alignItems="center"
            w="10"
            h="10"
            borderRadius="full"
            boxShadow="subtleBottomGlow"
            _focus={{ boxShadow: "subtleBottomGlow" }}
          />

          <IconButton
            variant="unstyled"
            onClick={onNextClickHandler}
            icon={<Icon name="arrows/chevron-right" width="24px" height="24px" />}
            aria-label={"Next Image"}
            bg="background.white"
            zIndex="popover"
            d="inline-flex"
            justifyContent="center"
            alignItems="center"
            w="10"
            h="10"
            borderRadius="full"
            boxShadow="subtleBottomGlow"
            _focus={{ boxShadow: "subtleBottomGlow" }}
          />
        </Flex>
      ) : null}
    </Modal>
  ) : null
}

export default React.memo(PersonaliserImageLightbox)

import { useShopify } from "@app/hooks/useShopify"
import {
  PersonaliserLabel,
  CropperShape,
  PersonaliserLabelCategory,
  CustomiserData,
  Color,
  PersonaliserAdditional,
  PersonaliserData,
} from "@root/types/custom-types/Personaliser/Data"

import { ShopifyServerData } from "@root/types/custom-types/Personaliser/ShopifyResponse"

export const usePersonaliser = (queryData: GatsbyTypes.PagePersonaliserQuery, serverData: ShopifyServerData) => {
  const { productNormaliser, collectionNormaliser } = useShopify()

  const { page, step1, step2, step3, step4, step5, labels, productLabels, messaging, orderSummary, orderSummaryUpsells } = queryData

  const labelsData: PersonaliserLabel[] = labels.edges.map(({ node }) => ({
    title: node.title as string,
    uuid: node.uuid as string,
    customiserControls: (node.customiserControls as string[]) || [""],
    cropperShape: (node.cropperShape as CropperShape | undefined) || "rect",
    cropperAspectRatio: node.cropperAspectRatio || "1/1",
    image: node.image as unknown as GatsbyTypes.SanityCustomImage,
    categories: node.categories?.map(category => ({
      title: category?.title as string,
      order: category?.order as number,
      handle: {
        current: category?.handle?.current as string,
      },
    })) as PersonaliserLabelCategory[],
    product: productNormaliser(JSON.parse(node.labelProduct?.shopify?.raw || "")),
  }))

  const data: PersonaliserData = {
    step1: {
      title: step1?.step1Title || "",
      description: step1?.step1Description || "",
      collections: Object.entries(serverData.step1)
        .map(sd => sd[1])
        .map(collectionNormaliser),
      errorMessage: step1?.step1RequiredMessage || "",
    },
    step2: {
      title: step2?.step2Title || "",
      description: step2?.step2Description || "",
      labels: labelsData,
      errorMessage: step2?.step2RequiredMessage || "",
    },
    step3: {
      title: "",
      description: "",
      errorMessage: step3?.step3RequiredMessage || "",
    },
    step4: {
      title: step4?.step4Title || "",
      description: step4?.step4Description || "",
      collections: Object.entries(serverData.step4)
        .map(sd => sd[1])
        .map(collectionNormaliser),
    },
    step5: {
      title: step5?.step5Title || "",
      description: step5?.step5Description || "",
      collections: Object.entries(serverData.step5)
        .map(sd => sd[1])
        .map(collectionNormaliser),
      errorMessage: step5?.step5RequiredMessage || "",
    },
  }

  const customiserData: CustomiserData = {
    title: step3?.step3Title || "",
    description: step3?.step3Description || "",
    image: {
      buttonLabel: step3?.step3ImageButtonLabel || "",
      title: step3?.step3ImageUploadTitle || "",
      description: step3?.step3ImageUploadDescription || "",
      smallDescription: step3?.step3ImageUploadSmallDescription || "",
      uploadButtonLabel: step3?.step3UploadButtonLabel || "",
      uploadLimit: step3?.step3ImageUploadLimit || 1,
      uploadErrorMessage: step3?.step3ImageUploadErrorMessage || "",
    },
    text: {
      buttonLabel: step3?.step3TextButtonLabel || "",
      topLineLabel: step3?.step3TextTopLineLabel || "",
      bottomLineLabel: step3?.step3TextBottomLineLabel || "",
      messageLabel: step3?.step3TextMessageLabel || "",
    },
    design: {
      buttonLabel: step3?.step3DesignButtonLabel || "",
      textColorLabel: step3?.step3TextColourLabel || "",
      textColors: step3?.step3TextColours as Color[],
      backgroundColorLabel: step3?.step3BackgroundColourLabel || "",
      backgroundColors: step3?.step3BackgroundColours as Color[],
    },
    edit: step3?.step3EditLabel || "",
    confirm: step3?.step3Confirm || "",
    loading: {
      upload: step3?.step3LoadingMessageUpload || "",
      customise: step3?.step3LoadingMessageCustomise || "",
    },
    imageUploadFailedErrorMessage: step3?.step3ImageUploadFailedErrorMessage || "",
  }

  const summaryData = {
    summaryTitle: page?.additionalSummaryTitle || "",
    summaryTotal: page?.additionalSummaryTotal || "",
    summaryTotalLine1: page?.additionalSummaryTotalLine1 || "",
    summaryTotalLine2: page?.additionalSummaryTotalLine2 || "",
    summaryButtonLabel: page?.additionalSummaryButtonLabel || "",
    edit: page?.additionalEdit || "",
    newLabel: page?.additionalNewLabel || "",
    remove: page?.additionalRemove || "",
    free: page?.additionalFree || "",
    step1: {
      label: page?.additionalSummaryStep1Label || "",
      placeholder: page?.additionalSummaryStep1Placeholder || "",
    },
    step2: {
      label: page?.additionalSummaryStep2Label || "",
      placeholder: page?.additionalSummaryStep2Placeholder || "",
    },
    step4: {
      label: page?.additionalSummaryStep4Label || "",
      placeholder: page?.additionalSummaryStep4Placeholder || "",
    },
    step5: {
      label: page?.additionalSummaryStep5Label || "",
      placeholder: page?.additionalSummaryStep5Placeholder || "",
    },
  }

  const orderSummaryData = {
    title: orderSummary?.summaryTitle || "",
    freeShippingThreshold: orderSummary?.summaryFreeShippingThreshold,
    freeShippingProgress: orderSummary?.summaryFreeShippingProgress || "",
    freeShippingReceived: orderSummary?.summaryFreeShippingReceived || "",
    addToCart: orderSummary?.summaryAddToCart || "",
    upsellData: {
      title: orderSummaryUpsells?.summaryUpsellsTitle || "",
      description: orderSummaryUpsells?.summaryUpsellsDescription || "",
      applyButtonLabel: orderSummaryUpsells?.summaryUpsellsApplyButtonLabel || "",
      ctaButtonLabel: orderSummaryUpsells?.summaryUpsellsButtonLabel || "",
      sanityProducts: orderSummaryUpsells?.summaryUpsellsProducts as unknown as GatsbyTypes.SanityProduct[],
    },
    success: {
      title: orderSummary?.summarySuccessTitle || "",
      message: orderSummary?.summarySuccessMessage || "",
      resetLabel: orderSummary?.summarySuccessResetLabel || "",
      cartLabel: orderSummary?.summarySuccessCartLabel || "",
    },
  }

  const additional: PersonaliserAdditional = {
    pagination: page?.additionalPagination || "",
    addProduct: page?.additionalAddProduct || "",
    selectProduct: page?.additionalSelectProduct || "",
    selectedProduct: page?.additionalSelectedProduct || "",
    aboutProduct: page?.additionalAboutProduct || "",
    enlargeProduct: page?.additionalEnlargeProduct || "",
    labelSubtitle: page?.additionalLabelSubtitle || "",
    onSale: page?.additionalOnSale || "",
    backToOptions: page?.additionalOptionsBack || "",
    navBack: page?.additionalNavBack || "",
    navNext: page?.additionalNavNext || "",
    errorPrefix: page?.additionalErrorMessagePrefix || "",
    addOnsTag: step5?.step5AddOnsBoxTag || "",
    noAddOnsTag: step5?.step5NoAddOnsBoxTag || "",
    giftboxErrorMessage: page?.additionalGiftBoxErrorMessage || "",
    giftboxErrorButtonLabel: page?.additionalGiftBoxErrorButtonLabel || "",
    labelFinaliseMessage: page?.additionalBuildingLabelMessage || "",
    labelFinaliseWarning: page?.additionalBuildingLabelWarning || "",
    labelTagLarge: step2?.step2LargeLabelTag || "",
    labelTagSmall: step2?.step2SmallLabelTag || "",
  }

  const matchingLabels = productLabels?.matchingIcons?.map(item => ({
    matchingTag: item?.matchingTag?.toLowerCase() || "",
    svgIcon: item?.svgIcon as unknown as GatsbyTypes.SanityImage,
  }))

  const messagingData = {
    sticker: {
      product: productNormaliser(JSON.parse(messaging?.messagingStickerProduct?.shopify?.raw || "")),
      title: messaging?.messagingStickerTitle || "",
      description: messaging?.messagingStickerDescription || "",
      options: (messaging?.messagingStickerOptions as string[]) || [""],
      datePlaceholder: messaging?.messagingStickerDatePlaceholder || "",
      requiredErrorMessage: messaging?.messagingStickerRequiredMessage || "",
    },
    giftcard: {
      product: productNormaliser(JSON.parse(messaging?.messagingGiftCardProduct?.shopify?.raw || "")),
      title: messaging?.messagingGiftCardTitle || "",
      description: messaging?.messagingGiftCardDescription || "",
      multipleDescription: messaging?.messagingGiftCardMultipleDescription || "",
      options: messaging?.messagingGiftCardOptions as string[] | [""],
      label: messaging?.messagingGiftCardLabel || "",
      messagePlaceholder: messaging?.messagingGiftCardMessagePlaceholder || "",
      requiredErrorMessage: messaging?.messagingGiftCardRequiredMessage || "",
    },
  }

  return { data, customiserData, summaryData, orderSummaryData, additional, matchingLabels, messagingData }
}

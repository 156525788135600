import { useCallback } from "react"

export type PollFunction = () => Promise<any>
export type PollValidate = (result: any) => boolean
export type PollParams = {
  fn: PollFunction
  validate: PollValidate
  interval: number
  maxAttempts: number
}
export const usePolling = () => {
  const poll = useCallback(async <ReturnType>(params: PollParams): Promise<ReturnType> => {
    const { fn, validate, interval, maxAttempts } = params

    let attempts = 0

    const executePoll = async (resolve: (value: ReturnType) => void, reject: (reason?: any) => void) => {
      const result = await fn()
      attempts++

      if (validate(result)) {
        return resolve(result) as ReturnType
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error("Exceeded max attempts").message)
      } else {
        setTimeout(executePoll, interval, resolve, reject)
      }
    }

    return new Promise(executePoll)
  }, [])

  return { poll }
}

import React, { useMemo } from "react"
import { Flex, Box, Text } from "@chakra-ui/react"

import { PersonaliserLabelCategory, FilterItem } from "@root/types/custom-types/Personaliser/Data"
import { NormalisedCollection } from "@root/types/custom-types/Collection/Collection"

type Props = {
  gridType: string
  collections: NormalisedCollection[] | undefined
  sanityFilterCategories: PersonaliserLabelCategory[] | undefined
  activeCategory: string
  setActiveCategory: React.Dispatch<React.SetStateAction<string>>
}

const PersonaliserFilters: React.FC<Props> = ({ gridType, collections, sanityFilterCategories, activeCategory, setActiveCategory }) => {
  const filtersList = useMemo(
    () =>
      gridType === "products"
        ? collections
            ?.map(collection => {
              if (!collection.title || !collection.handle) return

              return {
                label: collection.title,
                value: collection.handle,
              }
            })
            .filter((c): c is FilterItem => c !== undefined)
        : gridType === "labels"
          ? sanityFilterCategories?.map(category => {
              if (!category.title || !category.handle) return

              return {
                label: category.title,
                value: category.handle.current || "",
              }
            })
          : undefined,
    [gridType, collections, sanityFilterCategories]
  )

  const onFilterClick = (value: string) => setActiveCategory(value)

  return filtersList?.length ? (
    <Box
      pb={{ lg: "6" }}
      mb={{ lg: "6" }}
      borderBottom={{ lg: "solid 1px" }}
      borderColor={{ lg: "border.default" }}
      overflow="hidden"
      w="full"
    >
      <Flex
        overflow="auto"
        scrollSnapType="x mandatory"
        scrollPadding={{ base: "4", lg: "0" }}
        sx={{
          scrollbarWidth: { base: "none" },
          "::-webkit-scrollbar": {
            display: { base: "none" },
          },
        }}
        pb="1"
      >
        {filtersList.map(item => {
          const isActive = item?.value === activeCategory

          return item?.value ? (
            <Text
              key={item.value}
              as="span"
              d="inline-block"
              onClick={() => onFilterClick(item?.value)}
              size="textBase"
              flexShrink={0}
              scrollSnapAlign="start"
              py="1.5"
              px="4.5"
              mr="3"
              boxShadow="subtleBottomGlow"
              border="1px solid"
              borderRadius="3xxl"
              cursor="pointer"
              _first={{ ml: { base: "4", lg: "0" } }}
              _last={{ mr: { base: "4", lg: "0" } }}
              fontWeight={isActive ? "bold" : "normal"}
              color={isActive ? "background.white" : "typography.headlines900"}
              bg={isActive ? "brand.primary" : "background.white"}
              borderColor={isActive ? "transparent" : "border.light"}
            >
              {item?.label}
            </Text>
          ) : null
        })}
      </Flex>
    </Box>
  ) : null
}

export default React.memo(PersonaliserFilters)

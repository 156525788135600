import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { Icon } from "@app/components/Icon"

export type StickyPaginationFullWidthProps = {
  PersonaliserStickyPaginationData: any
}

const PersonaliserStickyPaginationFullWidth: React.FC<StickyPaginationFullWidthProps> = ({ PersonaliserStickyPaginationData }) => {
  const { inPersonaliser, isOpen, onPrevClickHandler, back, onToggle, summary, onNextClickHandler, next, isLarge } =
    PersonaliserStickyPaginationData
  return (
    <Box
      pos={inPersonaliser ? "static" : "fixed"}
      bottom="0"
      left="0"
      w="full"
      p={{ base: inPersonaliser ? "0" : "4", lg: "0" }}
      zIndex="overlay"
      opacity={isOpen && !isLarge ? "0" : "1"}
      transition="opacity 0.2s ease"
      pointerEvents={isOpen && !isLarge ? "none" : "initial"}
    >
      <Flex
        justifyContent="space-between"
        px={{ base: "4", lg: "20" }}
        py={{ base: "3", lg: "5" }}
        bg="brand.secondaryFocus"
        color="background.white"
        boxShadow={{ base: "subtleBottomGlow", lg: "twinShadow" }}
        borderRadius={{ base: inPersonaliser ? "none" : "xl", lg: "none" }}
      >
        <Box as="button" onClick={onPrevClickHandler} d="inline-flex" gap={{ base: "1.5", lg: "1" }} alignItems="center" cursor="pointer">
          <Box as="span" w="5" h="5">
            <Icon name="arrows/chevron-left" width="100%" height="100%" />
          </Box>

          <Text size="mediumLabel" textTransform="uppercase" fontWeight="bold">
            {back}
          </Text>
        </Box>

        {!isLarge ? (
          <Text as="button" onClick={onToggle} size="mediumLabel" textTransform="uppercase" fontWeight="bold" variant="underlined">
            {summary.summaryTitle}
          </Text>
        ) : null}

        <Box as="button" onClick={onNextClickHandler} d="inline-flex" gap={{ base: "1.5", lg: "1" }} alignItems="center" cursor="pointer">
          <Text size="mediumLabel" textTransform="uppercase" fontWeight="bold">
            {next}
          </Text>

          <Box as="span" w="5" h="5">
            <Icon name="arrows/chevron-right" width="100%" height="100%" />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default React.memo(PersonaliserStickyPaginationFullWidth)

import React, { useCallback } from "react"
import { Box, Button, HStack, Input, useNumberInput } from "@chakra-ui/react"

import { Icon } from "@app/components/Icon"

type Props = {
  initialValue?: number
  min?: number
  max?: number
  setQty: React.Dispatch<React.SetStateAction<number>>
  disabled?: boolean
}

const QuantityInput: React.FC<Props> = ({ initialValue, min, max, setQty, disabled }) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps, value } = useNumberInput({
    step: 1,
    defaultValue: initialValue || 1,
    min,
    max,
    isDisabled: disabled,
  })

  const onQtyChangeHandler = useCallback(() => {
    if (value) setQty(parseInt(value))
  }, [setQty, value])

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  // TODO: Stop icons from lazy loading

  return (
    <HStack
      border="1px solid"
      borderColor="border.default"
      borderRadius="xl"
      mb="2"
      bg={disabled ? "background.inputDisabled" : "background.white"}
      cursor={disabled ? "not-allowed" : "unset"}
    >
      <Button variant="quantity" {...dec} onClick={onQtyChangeHandler}>
        <Box as="span" w="5" h="5" color="border.focus">
          <Icon name="ui/minus" />
        </Box>
      </Button>
      <Input variant="quantity" {...input} onChange={onQtyChangeHandler} />
      <Button variant="quantity" {...inc} onClick={onQtyChangeHandler}>
        <Box as="span" w="5" h="5" color="border.focus">
          <Icon name="ui/plus" />
        </Box>
      </Button>
    </HStack>
  )
}
export default React.memo(QuantityInput)

import React, { useCallback } from "react"
import { AspectRatio, Box, Center, Flex, Image, Spinner, Text } from "@chakra-ui/react"

import { useShopify } from "@app/hooks/useShopify"
import { useImage } from "@app/hooks/useImage"
import { Icon } from "@app/components/Icon"

import { Bundle, OnRemoveClickHandler, ResetErrors, BundleKey } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  placeholder: string
  bundle: Bundle
  stepNumber: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  edit: string
  newLabel: string
  remove: string
  onRemoveClickHandler: OnRemoveClickHandler
  resetErrors: ResetErrors
  labelLoading: boolean
  finalLabelMessage: string
  onToggle?: () => void
  stepLabelData?: any
  onNextClickHandler?: () => void
  onPrevClickHandler?: () => void
  step?: number
}

const PersonaliserSummaryItem: React.FC<Props> = ({
  placeholder,
  bundle,
  stepNumber,
  setStep,
  edit,
  newLabel,
  remove,
  onRemoveClickHandler,
  resetErrors,
  labelLoading,
  finalLabelMessage,
  onToggle,
  stepLabelData,
  onNextClickHandler,
  onPrevClickHandler,
  step,
}) => {
  const { getGatsbyImage } = useImage()
  const { formatMoney, getFirstAvailableVariant } = useShopify()

  const bundleKey = `step${stepNumber}` as BundleKey
  const stepBundleData = bundle[bundleKey]

  const onEditClickHandler = useCallback(
    (overrideStep?: number) => {
      const editStep = overrideStep ? overrideStep : stepNumber
      if (onToggle) onToggle()

      resetErrors()
      setStep(editStep)
    },
    [onToggle, resetErrors, setStep, stepNumber]
  )

  if (stepBundleData && Object.keys(stepBundleData).length) {
    if (Array.isArray(stepBundleData) && stepBundleData.length) {
      return (
        <Flex flexDir="column">
          {stepBundleData.map(item => {
            const product = "id" in item ? item : item.product
            const availableVariant = getFirstAvailableVariant(product)
            if (!availableVariant) return null

            const image = getGatsbyImage(product.images)
            const amount = availableVariant.priceV2.amount
            const compareAtAmount = availableVariant.compareAtPriceV2?.amount
            const compareAtPrice = "id" in item ? formatMoney(compareAtAmount) : formatMoney(compareAtAmount * item.qty)
            const price = "id" in item ? formatMoney(amount) : formatMoney(amount * item.qty)
            const title = "id" in item ? product.title : `${product.title} x ${item.qty}`
            const onSale = !!compareAtAmount && parseFloat(compareAtAmount) > parseFloat(amount)

            return (
              <Flex key={product.id} justifyContent="space-between" alignItems="center" gap="5" w="full" mt="4" _first={{ mt: "0" }}>
                <Flex alignItems="center" gap="4">
                  <AspectRatio ratio={1 / 1} w="14" h="14">
                    <Image {...image[0]} />
                  </AspectRatio>

                  <Box w="calc(100% - 72px)">
                    <Text size="largeParagraph" color="typography.headlines900">
                      {title}
                    </Text>

                    {stepNumber !== 4 ? (
                      <Text as="button" size="textLinkSmall" variant="underlined" onClick={() => onEditClickHandler()}>
                        {edit}
                      </Text>
                    ) : (
                      <Text as="button" size="textLinkSmall" variant="underlined" onClick={() => onRemoveClickHandler(product.id, 4)}>
                        {remove}
                      </Text>
                    )}
                  </Box>
                </Flex>
                <Flex alignItems="baseline" gap="1.5">
                  {onSale ? (
                    <Text as="s" size="mediumParagraph" color={"typography.body600"}>
                      {compareAtPrice}
                    </Text>
                  ) : null}
                  <Text size="largeParagraph" color={onSale ? "functional.errorContent" : "typography.headlines900"}>
                    {price}
                  </Text>
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      )
    } else if ("categories" in stepBundleData) {
      const step3BundleData = bundle["step3"]
      const availableVariant = step3BundleData?.labelProduct
        ? getFirstAvailableVariant(step3BundleData?.labelProduct)
        : getFirstAvailableVariant(stepBundleData.product)
      if (!availableVariant) return null

      const step2AvailableVariant = bundle?.step2?.product ? getFirstAvailableVariant(bundle.step2.product) : undefined

      const personalisedImage = step3BundleData?.finalImage
      const image = !personalisedImage ? getGatsbyImage(stepBundleData.image) : { src: personalisedImage }
      const labelPrice = step3BundleData?.labelProduct
        ? parseFloat(availableVariant.priceV2 as unknown as string)
        : parseFloat(step2AvailableVariant?.priceV2 as unknown as string)

      return (
        <Flex justifyContent="space-between" alignItems="center" gap="5" w="full" mt="4" _first={{ mt: "0" }}>
          <Flex alignItems="center" gap="4">
            <Box
              pos="relative"
              bg="background.beige"
              borderRadius="sm"
              overflow="hidden"
              w="14"
              _before={{ d: "block", w: "full", pb: "100%", content: '""' }}
            >
              {labelLoading ? (
                <Center pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" w="calc(100% - 8px)" h="auto" maxH="100%">
                  <Spinner thickness="2px" speed="0.4s" emptyColor="background.white" color="brand.secondaryFocus" size="md" />
                </Center>
              ) : (
                <Image
                  {...image}
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  w="calc(100% - 8px)"
                  h="auto"
                  maxH="100%"
                />
              )}
            </Box>

            <Box>
              <Text size="largeParagraph" color="typography.headlines900">
                {labelLoading ? finalLabelMessage : stepBundleData.title}
              </Text>

              {!labelLoading ? (
                <Flex gap="2">
                  {bundle.step3 ? (
                    <Text as="button" size="textLinkSmall" variant="underlined" onClick={() => onEditClickHandler(3)}>
                      {edit}
                    </Text>
                  ) : null}

                  {bundle.step2 ? (
                    <Text as="button" size="textLinkSmall" variant="underlined" onClick={() => onEditClickHandler()}>
                      {newLabel}
                    </Text>
                  ) : null}
                </Flex>
              ) : null}
            </Box>
          </Flex>

          {!labelLoading ? (
            <Text size="largeParagraph" color="typography.headlines900">
              {formatMoney(labelPrice || 0)}
            </Text>
          ) : null}
        </Flex>
      )
    }
  }

  return (
    <Flex alignItems="center" gap="4">
      <Box as="span" w="14" h="14">
        <Icon name="ui/item-placeholder" width="100%" height="100%" />
      </Box>

      <Text
        onClick={
          step === 4 && stepLabelData.placeholder === "Select Gift Box"
            ? onNextClickHandler
            : step === 5 && stepLabelData.placeholder === "Select Add Ons"
              ? onPrevClickHandler
              : undefined
        }
        cursor={
          (step === 4 && stepLabelData.placeholder === "Select Gift Box") || (step === 5 && stepLabelData.placeholder === "Select Add Ons")
            ? "pointer"
            : "default"
        }
        size="textBase"
      >
        {placeholder}
      </Text>
    </Flex>
  )
}

export default React.memo(PersonaliserSummaryItem)

import React from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import PersonaliserStickyPagination from "./PersonaliserStickyPagination"
import { SetPersonliaserError } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  title?: string
  description?: string
  paginationText: string
  isLarge: boolean
  step?: number
  setStep?: React.Dispatch<React.SetStateAction<number>>
  actualStepsLength?: number
  setError?: SetPersonliaserError
  bundle?: any
}

const PersonaliserHeaderPagination: React.FC<Props> = ({
  title,
  description,
  paginationText,
  isLarge,
  step,
  setStep,
  setError,
  bundle,
  actualStepsLength,
}) =>
  title || description ? (
    <Box d={{ lg: "flex" }} justifyContent={{ lg: "space-between" }}>
      <Box p={{ base: "6", lg: "0" }} pb={{ base: "4", lg: "0" }} mb={{ lg: "6" }} textAlign={{ base: "center", lg: "left" }}>
        {title && (
          <Heading size="h4" color="typography.headlines900" mb={{ lg: "1.5" }}>
            {title}
          </Heading>
        )}
        {description && <Text size="largeParagraph">{description}</Text>}
      </Box>

      {isLarge ? (
        <Box display="flex" flexDirection="column">
          <Text size="labels" alignSelf="flex-end">
            {paginationText}
          </Text>
          {step !== actualStepsLength ? (
            <PersonaliserStickyPagination
              step={step}
              bundle={bundle}
              setStep={setStep}
              setError={setError}
              componentPlacement={"headerGrid"}
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  ) : null

export default React.memo(PersonaliserHeaderPagination)

import React from "react"

import { useMedia } from "@app/hooks/useMedia"
import PeronsaliserStickyPaginationButtons from "./PersonaliserStickyPaginationButtons"
import PeronsaliserStickyPaginationFullWidth from "./PersonaliserStickyPaginationFullWidth"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import {
  Bundle,
  BundleKey,
  CustomLabel,
  PersonaliserLabel,
  PersonaliserSummaryData,
  SetPersonliaserError,
} from "@root/types/custom-types/Personaliser/Data"

export type StickyPaginationProps = {
  bundle: Bundle
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  actualStepsLength: number
  back: string
  summary: PersonaliserSummaryData
  next: string
  onToggle: () => void
  isOpen: boolean
  setError: SetPersonliaserError
  inPersonaliser?: boolean
  landingPageUrl?: string
  componentPlacement?: string
}

const PersonaliserStickyPagination: React.FC<StickyPaginationProps> = ({
  bundle,
  step,
  setStep,
  actualStepsLength,
  back,
  summary,
  next,
  onToggle,
  isOpen,
  setError,
  inPersonaliser,
  landingPageUrl,
  componentPlacement,
}) => {
  const { isLarge } = useMedia()

  const requiredSteps = [1, 2, 3, 5]

  const onNextClickHandler = () => {
    if (step === actualStepsLength) return

    if (requiredSteps.includes(step)) {
      const bundleKey = `step${step}` as BundleKey
      const stepBundleData = bundle[bundleKey] as NormalisedProduct[] | PersonaliserLabel | CustomLabel

      const hasData = stepBundleData
        ? "categories" in stepBundleData || "finalImage" in stepBundleData
          ? !!stepBundleData
          : !!stepBundleData.length
        : false
      setError("stepRequired", !hasData)

      if (hasData) setStep(step => step + 1)
    } else if (step === 6) {
      const stickerIsInvalid = bundle.messaging.sticker.isSelected && !bundle.messaging.sticker.date
      setError("dateRequired", stickerIsInvalid)

      const giftCardIsInvalid = bundle.messaging.giftcard.isSelected && !bundle.messaging.giftcard.message
      setError("messageRequired", giftCardIsInvalid)

      if (!stickerIsInvalid && !giftCardIsInvalid) setStep(step => step + 1)
    } else {
      setStep(step => step + 1)
    }
  }

  const onPrevClickHandler = () => {
    if (step === 1) {
      if (landingPageUrl) window.location.href = `${window.location.origin}${landingPageUrl}`
      return
    }

    setError("stepRequired", false)
    setStep(step => step - 1)
  }

  const PersonaliserStickyPaginationData = {
    inPersonaliser,
    isOpen,
    onPrevClickHandler,
    back,
    onToggle,
    summary,
    onNextClickHandler,
    next,
    isLarge,
    step,
  }

  return componentPlacement === "headerGrid" ? (
    <PeronsaliserStickyPaginationButtons PersonaliserStickyPaginationData={PersonaliserStickyPaginationData} />
  ) : (
    <PeronsaliserStickyPaginationFullWidth PersonaliserStickyPaginationData={PersonaliserStickyPaginationData} />
  )
}

export default React.memo(PersonaliserStickyPagination)

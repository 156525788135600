import { useCallback } from "react"

import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useFunctions } from "@app/hooks/useFunctions"
import { useShopify } from "@app/hooks/useShopify"

import { AddToCartItem } from "@app/hooks/useCart"
import { usePolling } from "@app/hooks/usePolling"
import { PlacidCreateImageResponse } from "@app/api/placid-create-image"
import { PlacidGetImageResponse } from "@app/api/placid-get-image"
import { Bundle, CustomLabel } from "@root/types/custom-types/Personaliser/Data"

export type SanityBundleData = {
  id: number
  label: string
  products: string[]
}

type CreateSanityBundleFunctionReturn = {
  status: "error" | "success"
  body: { bundle: GatsbyTypes.SanityBundle }
}

export type AddBundleToCartParams = {
  bundle: Bundle
  setAtcLoading: React.Dispatch<React.SetStateAction<boolean>>
  addToCartMultiple: (items: AddToCartItem[], bundle?: BundleTrackingData) => Promise<void>
  labelTitle?: string
  bundleImage?: string
  URL?: string
  quantity?: number
  isMultipleShippingLocations?: boolean
}

export type CustomLabelData = Omit<CustomLabel, "finalImage">
export type ConfirmPersonalisedLabelParams = {
  UUID: string
  setLabelLoading: React.Dispatch<React.SetStateAction<boolean>>
  labelData: CustomLabelData
  setBundle: (data: CustomLabel) => void
}

export type BundleTrackingData = {
  title?: string
  imageUrl?: string
  URL?: string
}

type PlacidCreateImageFunctionReturn = { status: "error" | "success"; body: PlacidCreateImageResponse }
type PlacidGetImageFunctionReturn = { status: "error" | "success"; body: PlacidGetImageResponse }

export const useBundle = () => {
  const { settings } = useConfigContext()
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { callFunction } = useFunctions()
  const { poll } = usePolling()
  const { getFirstAvailableVariant } = useShopify()

  const createSanityBundle = useCallback(
    async (data: SanityBundleData) => {
      try {
        const { status, body } = await callFunction<CreateSanityBundleFunctionReturn>("bundle-create", { ...data })
        if (status === "error") throw "Failed to create Sanity Bundle document"

        if (status !== "success" || typeof body === "string") {
          throw "Unknown issue has occurred with Gatsby function call: bundle-create"
        }

        return body.bundle.title
      } catch (error) {
        console.error(error)
      }
    },
    [callFunction]
  )

  const uploadImageToSanity = useCallback(
    async (imageUrl: string) => {
      try {
        const fileName = `${settings.personaliser.bundle.sanityImageNamePrefix}${new Date()
          .toTimeString()
          .slice(0, 10)
          .replaceAll(":", "-")
          .replace(/\s/g, "")}${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`
        const imageBlob = await fetch(imageUrl).then(r => r.blob())
        const finalImage = new File([imageBlob], fileName, { type: imageBlob.type, lastModified: new Date().getTime() })

        const formData = new FormData()
        formData.append("file", finalImage)
        const imageResponse = (await fetch("/api/bundle-upload-sanity", {
          method: "POST",
          body: formData,
        }).then(r => r.json())) as { body: { url: string; id: string }; status: string }

        console.log("nik imageResponse", imageResponse)

        return {
          url: imageResponse.body.url,
          id: imageResponse.body.id,
        }
      } catch (error) {
        console.error(error)
      }
    },
    [settings]
  )

  const dataURLtoFile = useCallback((dataurl: string, filename: string) => {
    if (!dataurl) return

    const arr = dataurl.split(",")
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }, [])

  const addBundleToCart = useCallback(
    async (params: AddBundleToCartParams) => {
      const { bundle, setAtcLoading, addToCartMultiple, labelTitle, bundleImage, URL, quantity = 1 } = params
      const {
        lineItemPropKeyBundleId,
        lineItemPropKeyAlcohol,
        lineItemPropKeyLabelImage,
        lineItemPropKeyLabelTitle,
        lineItemPropKeyAddOns,
        lineItemPropKeyGiftcard,
        lineItemPropKeyGiftcardMessage,
        lineItemPropKeyGiftBox,
        lineItemPropKeySticker,
        lineItemPropKeyStickerDate,
        lineItemPropKeyGiftcardMultiple,
      } = settings.personaliser.bundle

      try {
        const { step1, step3, step4, step5, messaging } = bundle
        const { sticker, giftcard } = messaging

        if (!step1) throw new Error("Missing step 1 product")
        if (!step3) throw new Error("Missing label")
        if (!step5) throw new Error("Missing gift box product")

        setAtcLoading(true)

        const sanityImage = await uploadImageToSanity(step3.finalImage)
        const sanityImageId = sanityImage?.id
        const sanityImageUrl = sanityImage?.url
        if (!sanityImageId || !sanityImageUrl) throw new Error("Sanity image upload failed")

        const productsList = [step1[0].title, step3.labelProduct.title]

        if (step4?.length) step4.forEach(addOn => productsList.push(addOn.product.title))
        productsList.push(step5[0].title)

        if (sticker.product) productsList.push(sticker.product.title)
        if (giftcard.product) productsList.push(giftcard.product.title)

        const bundleId = await createSanityBundle({
          id: new Date().getTime(),
          label: sanityImageId,
          products: productsList,
        })

        if (!bundleId) throw new Error("Missing bundleId, check that bundle is being created correctly.")

        const bundleAttributes = [
          {
            key: lineItemPropKeyBundleId,
            value: `${bundleId}`,
          },
        ]

        const labelAttributes = [
          ...bundleAttributes,
          {
            key: lineItemPropKeyLabelImage,
            value: sanityImageUrl,
          },
        ]

        if (labelTitle) {
          labelAttributes.push({
            key: lineItemPropKeyLabelTitle,
            value: labelTitle,
          })
        }

        const itemsToAdd: AddToCartItem[] = [
          {
            variantId: step1[0].variants[0].id,
            quantity,
            customAttributes: [
              ...bundleAttributes,
              {
                key: lineItemPropKeyAlcohol,
                value: "true",
              },
            ],
          },
          {
            variantId: decodeBase64(step3.labelProduct.variants[0].id),
            quantity,
            customAttributes: labelAttributes,
          },
          {
            variantId: decodeBase64(step5[0].variants[0].id),
            quantity,
            customAttributes: [
              ...bundleAttributes,
              {
                key: lineItemPropKeyGiftBox,
                value: "true",
              },
            ],
          },
        ]

        if (step4?.length) {
          step4.forEach(addOn => {
            const availableVariant = getFirstAvailableVariant(addOn.product)
            if (!availableVariant) return

            itemsToAdd.push({
              variantId: decodeBase64(availableVariant.id),
              quantity: addOn.qty * quantity,
              customAttributes: [
                ...bundleAttributes,
                {
                  key: lineItemPropKeyAddOns,
                  value: "true",
                },
              ],
            })
          })
        }

        if (sticker.product && sticker.date) {
          itemsToAdd.push({
            variantId: decodeBase64(sticker.product.variants[0].id),
            quantity,
            customAttributes: [
              ...bundleAttributes,
              {
                key: lineItemPropKeySticker,
                value: "true",
              },
              {
                key: lineItemPropKeyStickerDate,
                value: sticker.date.toLocaleDateString(),
              },
            ],
          })
        }

        if (giftcard.product && (giftcard.message || giftcard.isMultiple)) {
          itemsToAdd.push({
            variantId: decodeBase64(giftcard.product.variants[0].id),
            quantity,
            customAttributes: [
              ...bundleAttributes,
              {
                key: lineItemPropKeyGiftcard,
                value: "true",
              },
              {
                key: lineItemPropKeyGiftcardMessage,
                value: giftcard.message,
              },
              {
                key: lineItemPropKeyGiftcardMultiple,
                value: giftcard.isMultiple ? "true" : "false",
              },
            ],
          })
        }

        await addToCartMultiple(itemsToAdd, { title: labelTitle, imageUrl: bundleImage, URL })

        setAtcLoading(false)
      } catch (e) {
        console.error(e)
        setAtcLoading(false)
      }
    },
    [createSanityBundle, uploadImageToSanity, decodeBase64, getFirstAvailableVariant, settings]
  )

  const confirmPersonalisedLabel = async (params: ConfirmPersonalisedLabelParams) => {
    try {
      const { textLayerPrimary, textLayerSecondary, imageLayerPrimary, backgroundLayerPrimary } = settings.personaliser.placid
      const { UUID, setLabelLoading, labelData, setBundle } = params
      const { textLine1, textLine2, textColor, backgroundColor, photoUpload } = labelData

      setLabelLoading(true)

      const generateImageData = {
        create_now: false,
        layers: {
          [`${textLayerPrimary}`]: {
            text: textLine1 || " ",
            text_color: textColor?.hex,
          },
          [`${textLayerSecondary}`]: {
            text: textLine2 || " ",
            text_color: textColor?.hex,
          },
          [`${backgroundLayerPrimary}`]: {
            background_color: backgroundColor?.hex,
          },
          [`${imageLayerPrimary}`]: {
            image: photoUpload,
          },
        },
      }

      const { status, body } = await callFunction<PlacidCreateImageFunctionReturn>("placid-create-image", {
        uuid: UUID,
        placidData: generateImageData,
      })

      if (status === "error") {
        if (typeof body === "string") throw new Error(body)
        setLabelLoading(false)
        throw new Error(body?.errors?.toString?.())
      }

      if (status !== "success" || typeof body === "string") {
        setLabelLoading(false)
        throw "Unknown issue has occurred with Gatsby function call: placid-create-image"
      }

      const { image_url, polling_url } = body

      if (image_url) {
        setBundle({ ...labelData, finalImage: image_url })
        setLabelLoading(false)
      } else {
        const getPlacidImage = () => callFunction<PlacidGetImageFunctionReturn>("placid-get-image", { pollingUrl: polling_url })
        const checkImageReady = (response: PlacidGetImageFunctionReturn) => response.body.status === "finished" && !!response.body.image_url

        poll<PlacidGetImageFunctionReturn>({ fn: getPlacidImage, validate: checkImageReady, interval: 1000, maxAttempts: 10 })
          .then(response => {
            const imageUrl = response.body.image_url
            if (!imageUrl) throw "Image url missing from Placid get response"
            setBundle({ ...labelData, finalImage: imageUrl })
            setLabelLoading(false)
          })
          .catch(err => {
            setLabelLoading(false)
            console.error(err)
          })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return { dataURLtoFile, uploadImageToSanity, createSanityBundle, addBundleToCart, confirmPersonalisedLabel }
}

import React, { useEffect } from "react"
import { Box, Flex, FormControl, FormLabel, Grid, Heading, Image, Radio, RadioGroup, Text, Textarea } from "@chakra-ui/react"
import DatePicker from "react-date-picker"

import { useMedia } from "@app/hooks/useMedia"
import PersonaliserSummaryWidget from "@app/components/Personaliser/PersonaliserSummaryWidget"
import CustomAlert from "@app/components/CustomAlert"
import {
  Bundle,
  MessagingData,
  OnRemoveClickHandler,
  PersonaliserError,
  PersonaliserSummaryData,
  ResetErrors,
} from "@root/types/custom-types/Personaliser/Data"

type Props = {
  messagingData: MessagingData
  setBundle: React.Dispatch<React.SetStateAction<Bundle>>
  bundle: Bundle
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  visualStepsLength: number
  summary: PersonaliserSummaryData
  onRemoveClickHandler: OnRemoveClickHandler
  errors: PersonaliserError[]
  resetErrors: ResetErrors
  labelLoading: boolean
  finalLabelMessage: string
}

const PersonaliserMessaging: React.FC<Props> = ({
  messagingData,
  setBundle,
  bundle,
  step,
  setStep,
  visualStepsLength,
  summary,
  onRemoveClickHandler,
  errors,
  resetErrors,
  labelLoading,
  finalLabelMessage,
}) => {
  const { isLarge } = useMedia()

  const radioHandler = (value: string, type: "sticker" | "giftcard") => {
    setBundle(prevBundle => {
      const updatedObject =
        type === "sticker"
          ? {
              sticker: {
                ...prevBundle.messaging.sticker,
                isSelected: value === "true",
              },
            }
          : {
              giftcard: {
                ...prevBundle.messaging.giftcard,
                isSelected: value === "true",
              },
            }

      return {
        ...prevBundle,
        messaging: {
          ...prevBundle.messaging,
          ...updatedObject,
        },
      }
    })
  }

  const onDateChangeHandler = (date: Date) => {
    setBundle(prevBundle => ({
      ...prevBundle,
      ...{
        messaging: {
          ...prevBundle.messaging,
          sticker: {
            ...prevBundle.messaging.sticker,
            product: messagingData.sticker.product,
            date: date,
          },
        },
      },
    }))
  }

  const onMessageChangeHandler = (e: React.SyntheticEvent) => {
    setBundle(prevBundle => ({
      ...prevBundle,
      ...{
        messaging: {
          ...prevBundle.messaging,
          giftcard: {
            ...prevBundle.messaging.giftcard,
            product: messagingData.giftcard.product,
            message: (e.target as HTMLTextAreaElement).value || "",
          },
        },
      },
    }))
  }

  useEffect(() => {
    if (bundle.messaging.sticker.isSelected === true) return

    setBundle(prevBundle => ({
      ...prevBundle,
      ...{
        messaging: {
          ...prevBundle.messaging,
          sticker: {
            ...prevBundle.messaging.sticker,
            product: undefined,
            date: undefined,
          },
        },
      },
    }))
  }, [bundle.messaging.sticker.isSelected, setBundle])

  useEffect(() => {
    if (bundle.messaging.giftcard.isSelected === true) return

    setBundle(prevBundle => ({
      ...prevBundle,
      ...{
        messaging: {
          ...prevBundle.messaging,
          giftcard: {
            ...prevBundle.messaging.giftcard,
            product: undefined,
            message: undefined,
          },
        },
      },
    }))
  }, [bundle.messaging.giftcard.isSelected, setBundle])

  const { sticker, giftcard } = messagingData
  const stickerImage = messagingData.sticker.product?.image
  const dateError = errors.find(e => e.type === "dateRequired")
  const messageError = errors.find(e => e.type === "messageRequired")

  /**
   * TODO: after previous sprints are merged in. Replace sticker and gift card code with abstracted personaliser components.
   * Use the components that were extracted as part of the PDP functionality update.
   */

  return (
    <Grid
      templateColumns={{ base: "1fr", lg: "1fr 2fr" }}
      gap={{ lg: "15" }}
      pt={{ lg: "12" }}
      px={{ lg: "20" }}
      pb={{ lg: "22.5" }}
      bg="background.white"
    >
      {isLarge ? (
        <Box gridArea={{ lg: "1/1/2/2" }}>
          <PersonaliserSummaryWidget
            bundle={bundle}
            setBundle={setBundle}
            step={step}
            setStep={setStep}
            visualStepsLength={visualStepsLength}
            summary={summary}
            onRemoveClickHandler={onRemoveClickHandler}
            resetErrors={resetErrors}
            labelLoading={labelLoading}
            finalLabelMessage={finalLabelMessage}
          />
        </Box>
      ) : null}

      <Box gridArea={{ lg: "1/2/2/3" }} p={{ base: "4", lg: "0" }} pb={{ base: "6" }}>
        <Box p={{ base: "4", lg: "8" }} boxShadow="subtleBottomGlow" borderRadius="xl" mb={{ base: "4", lg: "8" }}>
          <Flex gap={{ base: "4" }} mb="3">
            <Box>
              {sticker.title ? (
                <Heading size="headlines" color="typography.headlines900" mb="1">
                  {sticker.title}
                </Heading>
              ) : null}

              {sticker.description ? <Text size="largeParagraph">{sticker.description}</Text> : null}
            </Box>

            {stickerImage ? <Image src={stickerImage} alt="" htmlWidth={72} htmlHeight={72} w="18" h="18" /> : null}
          </Flex>

          {sticker.options.length ? (
            <RadioGroup
              defaultValue={`${bundle.messaging.sticker.isSelected}`}
              name="sticker"
              onChange={value => radioHandler(value, "sticker")}
              display="flex"
              gap="4"
            >
              {sticker.options.map((option, index) => (
                <Radio key={option.toLowerCase()} value={index === 0 ? "true" : "false"}>
                  {option}
                </Radio>
              ))}
            </RadioGroup>
          ) : null}

          {sticker.datePlaceholder && bundle.messaging.sticker.isSelected ? (
            <Box mt="3">
              <DatePicker
                clearIcon={null}
                format="dd/MM/y"
                minDate={new Date(Date.now())}
                onChange={onDateChangeHandler}
                returnValue="end"
                value={bundle.messaging.sticker.date}
              />
            </Box>
          ) : null}

          {dateError && dateError.active ? (
            <CustomAlert status="error" withoutIcon={true} mt="3">
              {dateError.message}
            </CustomAlert>
          ) : null}
        </Box>

        <Box p={{ base: "4", lg: "8" }} boxShadow="subtleBottomGlow" borderRadius="xl">
          <Box mb={{ base: "3", lg: "6" }}>
            <Box>
              {giftcard.title ? (
                <Heading size="headlines" color="typography.headlines900" mb="1">
                  {giftcard.title}
                </Heading>
              ) : null}

              {giftcard.description ? <Text size="largeParagraph">{giftcard.description}</Text> : null}
            </Box>
          </Box>

          {giftcard.options.length ? (
            <RadioGroup
              defaultValue={`${bundle.messaging.giftcard.isSelected}`}
              name="giftcard"
              onChange={value => radioHandler(value, "giftcard")}
              display="flex"
              gap="4"
            >
              {giftcard.options.map((option, index) => (
                <Radio key={option.toLowerCase()} value={index === 0 ? "true" : "false"}>
                  {option}
                </Radio>
              ))}
            </RadioGroup>
          ) : null}

          {bundle.messaging.giftcard.isSelected ? (
            <Box mt="6">
              {giftcard.label ? (
                <Text size="largeParagraph" mb="1.5">
                  {giftcard.label}
                </Text>
              ) : null}

              {giftcard.messagePlaceholder ? (
                <FormControl>
                  <Textarea placeholder=" " onChange={onMessageChangeHandler} value={bundle.messaging.giftcard.message} />
                  <FormLabel>{giftcard.messagePlaceholder}</FormLabel>
                </FormControl>
              ) : null}
            </Box>
          ) : null}

          {messageError && messageError.active ? (
            <CustomAlert status="error" withoutIcon={true} mt="3">
              {messageError.message}
            </CustomAlert>
          ) : null}
        </Box>
      </Box>
    </Grid>
  )
}

export default React.memo(PersonaliserMessaging)

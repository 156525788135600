import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Grid, Skeleton, Button } from "@chakra-ui/react"

import { useMedia } from "@app/hooks/useMedia"
import PersonaliserProductItem from "@app/components/Personaliser/PersonaliserProductItem"
import PersonaliserFilters from "@app/components/Personaliser/PersonaliserFilters"
import PersonaliserSummaryWidget from "@app/components/Personaliser/PersonaliserSummaryWidget"
import PersonaliserLabelItem from "@app/components/Personaliser/PersonaliserLabelItem"
import PersonaliserHeaderPagination from "@app/components/Personaliser/PersonaliserHeaderPagination"
import CustomAlert from "@app/components/CustomAlert"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { NormalisedCollection } from "@root/types/custom-types/Collection/Collection"
import {
  Bundle,
  BundleKey,
  MatchingLabel,
  ModalImage,
  OnAddProductClickHandler,
  OnRemoveClickHandler,
  PersonaliserAdditional,
  PersonaliserError,
  PersonaliserLabel,
  PersonaliserLabelCategory,
  PersonaliserSummaryData,
  ResetErrors,
  SetPersonliaserError,
} from "@root/types/custom-types/Personaliser/Data"

export type PersonaliserGridProps = {
  bundle: Bundle
  setBundle: React.Dispatch<React.SetStateAction<Bundle>>
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  visualStepsLength: number
  title?: string
  description?: string
  collections?: NormalisedCollection[]
  sanityLabels?: PersonaliserLabel[]
  additional: PersonaliserAdditional
  summary: PersonaliserSummaryData
  onRemoveClickHandler: OnRemoveClickHandler
  onAddProductClickHandler: OnAddProductClickHandler
  setIsProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsImageModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isImageModalOpen: boolean
  modalImages: ModalImage[]
  setModalImages: React.Dispatch<React.SetStateAction<ModalImage[]>>
  setModalProduct: React.Dispatch<React.SetStateAction<NormalisedProduct | undefined>>
  matchingLabels?: MatchingLabel[]
  errors: PersonaliserError[]
  setError: SetPersonliaserError
  resetErrors: ResetErrors
  labelLoading: boolean
  actualStepsLength?: number
}

const PersonaliserGrid: React.FC<PersonaliserGridProps> = ({
  bundle,
  setBundle,
  step,
  setStep,
  visualStepsLength,
  title,
  description,
  collections,
  sanityLabels,
  additional,
  summary,
  onRemoveClickHandler,
  onAddProductClickHandler,
  setIsProductModalOpen,
  setIsImageModalOpen,
  setModalImages,
  setModalProduct,
  errors,
  setError,
  resetErrors,
  labelLoading,
  actualStepsLength,
}) => {
  const { isLarge } = useMedia()
  const [items, setItems] = useState<NormalisedProduct[] | PersonaliserLabel[] | undefined>([])
  const [itemsToShowCount, setItemsToShowCount] = useState(8)
  const [activeCategory, setActiveCategory] = useState("")

  const gridType = useMemo(
    () => (collections && collections?.length ? "products" : sanityLabels && sanityLabels.length ? "labels" : ""),
    [collections, sanityLabels]
  )
  const paginationText = useMemo(
    () => additional.pagination.replace("%step%", step.toString()).replace("%stepsTotal%", visualStepsLength.toString()),
    [additional.pagination, step, visualStepsLength]
  )

  const sanityFilterCategories = useMemo(() => {
    const categoriesAll = sanityLabels?.map(label => label?.categories?.map(category => category)).flat()
    const uniqueCategories: PersonaliserLabelCategory[] = []
    categoriesAll?.forEach(category => {
      if (!category) return

      if (!uniqueCategories.find(cat => cat.handle?.current === category?.handle?.current)) uniqueCategories.push(category)
    })

    uniqueCategories.sort((a, b) => (a?.order > b?.order ? 1 : a?.order <= b?.order ? -1 : 0))

    return uniqueCategories
  }, [sanityLabels])

  const onLabelClickHandler = useCallback(
    (item: PersonaliserLabel) => {
      const bundleKey = `step${step}` as BundleKey
      setBundle(prevBundle => {
        return {
          ...prevBundle,
          ...{
            [bundleKey]: item,
          },
          ...{
            step3: undefined,
          },
        }
      })

      setError("stepRequired", false)
      setStep(step + 1)
    },
    [setBundle, setError, setStep, step]
  )

  const onLabelEnlargeHandler = useCallback(
    (images: ModalImage[] | undefined) => {
      if (!images) return

      setModalImages(images)
      setIsImageModalOpen(true)
    },
    [setModalImages, setIsImageModalOpen]
  )

  const stepError = errors && errors.find(e => e.type === "stepRequired")

  useEffect(() => {
    if (step === 2) {
      const initialCategory = sanityFilterCategories[0].handle?.current || ""
      setActiveCategory(initialCategory)
    } else {
      const initialHandle = collections?.length ? collections[0].handle || "" : ""
      setActiveCategory(initialHandle)
    }
  }, [step, sanityFilterCategories, collections])

  const filterGiftboxes = useCallback(
    (items: NormalisedProduct[], filterTag: string) => items.filter((i): i is NormalisedProduct => i.tags.includes(filterTag)),
    []
  )

  useEffect(() => {
    const activeCollection = collections?.length
      ? collections?.find(collection => collection.handle === activeCategory) || collections[0]
      : null
    const activeLabels = sanityLabels?.filter(label => label.categories?.find(category => category?.handle?.current === activeCategory))

    let items = step === 2 ? activeLabels : activeCollection?.products || []

    /**
     * For the label template selection step
     * Detect if the step 1 product has a label size tag and if so filter the labels appropriately based on this
     */
    if (step === 2 && items?.length && bundle.step1?.length) {
      const step1ItemTags = bundle.step1[0].tags
      const labelSizeTag = step1ItemTags.includes(additional.labelTagLarge)
        ? additional.labelTagLarge
        : step1ItemTags.includes(additional.labelTagSmall)
          ? additional.labelTagSmall
          : ""

      items = labelSizeTag ? (items as PersonaliserLabel[]).filter(i => i.product.tags.includes(labelSizeTag)) : items
    }

    /**
     * For the gift box step
     * If customer has added add ons into bundle, only show add on boxes. Otherwise show the reverse.
     */
    if (step === 5 && items?.length) {
      if (bundle.step4?.length) {
        items = filterGiftboxes(items as NormalisedProduct[], additional.addOnsTag)
      } else {
        items = filterGiftboxes(items as NormalisedProduct[], additional.noAddOnsTag)
      }
    }

    setItems(items)
  }, [
    collections,
    activeCategory,
    sanityLabels,
    step,
    bundle.step4,
    filterGiftboxes,
    additional.addOnsTag,
    additional.noAddOnsTag,
    additional.labelTagLarge,
    additional.labelTagSmall,
    bundle.step1,
  ])

  const handleShowMoreItems = () => {
    setItemsToShowCount(prev => prev + 6)
  }

  // Remove items that are not available for sale from the grid
  useEffect(() => {
    const filteredItems = items?.filter(item => item?.availableForSale !== false)
    if (filteredItems?.length && filteredItems?.length != items?.length) {
      setItems(filteredItems)
    }
  }, [items])

  return (
    <Grid templateColumns={{ base: "100%", lg: "1fr 2fr" }} gap={{ lg: "15" }} pt={{ lg: "12" }} px={{ lg: "20" }} pb={{ lg: "22.5" }}>
      {isLarge ? (
        <Box gridArea={{ lg: "1/1/2/2" }}>
          <PersonaliserSummaryWidget
            bundle={bundle}
            setBundle={setBundle}
            step={step}
            setStep={setStep}
            visualStepsLength={visualStepsLength}
            summary={summary}
            onRemoveClickHandler={onRemoveClickHandler}
            resetErrors={resetErrors}
            labelLoading={labelLoading}
            finalLabelMessage={additional.labelFinaliseMessage}
          />
        </Box>
      ) : null}

      <Box gridArea={{ lg: "1/2/2/3" }}>
        <PersonaliserHeaderPagination
          title={title}
          description={description}
          setError={setError}
          bundle={bundle}
          paginationText={paginationText}
          isLarge={isLarge}
          step={step}
          setStep={setStep}
          actualStepsLength={actualStepsLength}
        />

        {collections?.length || sanityFilterCategories.length ? (
          <PersonaliserFilters
            gridType={gridType}
            collections={collections}
            sanityFilterCategories={sanityFilterCategories}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        ) : null}

        {stepError?.active && stepError.message ? (
          <Box px={{ base: "4", md: "8", lg: "0" }} pt={{ base: "4", lg: "0" }} mb={{ lg: "6" }}>
            <CustomAlert status="error" title={additional.errorPrefix} withoutIcon={true}>
              {stepError.message}
            </CustomAlert>
          </Box>
        ) : null}

        <Grid
          gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }}
          columnGap={{ base: "4", lg: "5" }}
          rowGap={{ base: "4", lg: "8" }}
          pt={{ base: "4", lg: "0" }}
          px={{ base: "4", md: "8", lg: "0" }}
          pb={{ base: "8", md: "10", lg: "0" }}
        >
          {items && items.length
            ? items.slice(0, itemsToShowCount).map(item => {
                if ("collections" in item) {
                  return (
                    <PersonaliserProductItem
                      key={item.id}
                      item={item}
                      textAdd={additional.addProduct}
                      textAbout={additional.aboutProduct}
                      textOnSale={additional.onSale}
                      textSelected={additional.selectedProduct}
                      bundle={bundle}
                      step={step}
                      setIsProductModalOpen={setIsProductModalOpen}
                      setModalProduct={setModalProduct}
                      onAddProductClickHandler={onAddProductClickHandler}
                      onRemoveClickHandler={onRemoveClickHandler}
                    />
                  )
                } else if ("categories" in item) {
                  return (
                    <PersonaliserLabelItem
                      key={item.title?.toLowerCase()}
                      item={item}
                      textSubtitle={additional.labelSubtitle}
                      textEnlarge={additional.enlargeProduct}
                      textSelect={additional.selectProduct}
                      onClickHandler={onLabelClickHandler}
                      onEnlargeHandler={onLabelEnlargeHandler}
                    />
                  )
                }
              })
            : [...Array(16)].map((i, index) => <Skeleton key={index} isLoaded={false} w="full" pb="calc(100% + 174px)" />)}
        </Grid>
        {items && items.length > itemsToShowCount && (
          <Box width="100%" marginTop="30px" display="flex" justifyContent="center">
            <Button variant="solidSecondary" onClick={handleShowMoreItems} minW="30">
              See More
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export default React.memo(PersonaliserGrid)

import React, { useMemo } from "react"

import { usePersonaliser } from "@app/hooks/usePersonaliser"
import Reviews from "@app/components/Sections/Reviews/Reviews"
import SimpleHero from "@app/components/Sections/SimpleHero/SimpleHero"
import Personaliser from "@app/components/Personaliser/Personaliser"

import { Props } from "@app/pages/build-your-own-gift-box"

const PersonaliserPage: React.FC<Props> = ({ data, serverData }) => {
  const { page } = data
  const {
    data: personaliserData,
    customiserData,
    summaryData,
    additional,
    matchingLabels,
    messagingData,
    orderSummaryData,
  } = usePersonaliser(data, serverData)

  const landingPageUrl = useMemo(() => {
    const landingPageHandle = (page?.landingPageUrl as any)?.handle.current as string | undefined
    return landingPageHandle ? `/${landingPageHandle}` : undefined
  }, [page?.landingPageUrl])

  return (
    <>
      <SimpleHero title={page?.pageTitle} image={page?.images} />
      <Personaliser
        data={personaliserData}
        customiserData={customiserData}
        additional={additional}
        summary={summaryData}
        matchingLabels={matchingLabels}
        messagingData={messagingData}
        orderSummaryData={orderSummaryData}
        landingPageUrl={landingPageUrl}
      />
      <Reviews title={page?.reviewsTitle} theme="secondary" />
    </>
  )
}

export default PersonaliserPage

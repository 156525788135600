import React, { useCallback } from "react"
import { Box, Button, ButtonGroup, Center, Heading, Modal, ModalBody, ModalContent, ModalOverlay, Text, VStack } from "@chakra-ui/react"

import config from "@root/config.js"
import { Icon } from "@app/components/Icon"
import Link from "@app/components/Link"

import { OrderSummarySuccessData } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  data: OrderSummarySuccessData
  resetBundle: () => void
  showSuccessModal: boolean
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
}

const PersonaliserSuccessWidget: React.FC<Props> = ({ data, resetBundle, showSuccessModal, setShowSuccessModal }) => {
  const { title, message, resetLabel, cartLabel } = data

  const onResetHandler = useCallback(() => resetBundle(), [resetBundle])
  const onCloseHandler = useCallback(() => setShowSuccessModal(false), [setShowSuccessModal])

  return (
    <Modal onClose={onCloseHandler} isOpen={showSuccessModal} closeOnEsc={false} closeOnOverlayClick={false} isCentered={true} size="md">
      <ModalOverlay bg="background.overlay" />

      <ModalContent mx={{ base: "4", lg: "unset" }}>
        <ModalBody px={{ base: "4", lg: "12" }} py={{ base: "5", lg: "12" }}>
          <VStack spacing="4" textAlign="center" alignItems="stretch">
            <Center>
              <Box as="span" w="10" h="10">
                <Icon name="ui/check-circle" width="100%" height="100%" />
              </Box>
            </Center>

            {title ? (
              <Heading as="h4" size="h4" color="typography.headlines900">
                {title}
              </Heading>
            ) : null}

            {message ? <Text size="largeParagraph">{message}</Text> : null}

            {resetLabel && cartLabel ? (
              <ButtonGroup spacing="3">
                <Button variant="solidSecondary" onClick={onResetHandler} flexGrow={1}>
                  {resetLabel}
                </Button>

                <Button as={Link} to={config.settings.routes.CART} variant="outlineSecondary" flexGrow={1}>
                  {cartLabel}
                </Button>
              </ButtonGroup>
            ) : null}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(PersonaliserSuccessWidget)

import React, { useEffect, useState, useCallback, useMemo, useRef } from "react"
import { Box, Container, Fade, useDisclosure } from "@chakra-ui/react"
import { Global as EmotionGlobal } from "@emotion/react"

import { useMedia } from "@app/hooks/useMedia"
import PersonaliserGrid from "@app/components/Personaliser/PersonaliserGrid"
import PersonaliserLabelCustomiser from "@app/components/Personaliser/PersonaliserLabelCustomiser"
import PersonaliserStickyPagination from "@app/components/Personaliser/PersonaliserStickyPagination"
import PersonaliserSummaryWidget from "@app/components/Personaliser/PersonaliserSummaryWidget"
import PersonaliserMessaging from "@app/components/Personaliser/PersonaliserMessaging"
import PersonaliserOrderSummary from "@app/components/Personaliser/PersonaliserOrderSummary"
import PersonaliserProductDrawer from "@app/components/Personaliser/PersonaliserProductDrawer"
import PersonaliserImageLightbox from "@app/components/Personaliser/PersonaliserImageLightbox"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { useAppContext } from "@app/providers/app"
import {
  PersonaliserData,
  CustomiserData,
  PersonaliserAdditional,
  PersonaliserSummaryData,
  MatchingLabel,
  MessagingData,
  OrderSummaryData,
  Bundle,
  Addon,
  PersonaliserDataKey,
  PersonaliserError,
  PersonaliserErrorType,
  ModalImage,
  OnRemoveClickHandler,
  BundleKey,
  OnAddProductClickHandler,
  OnAddToSelectionHandler,
} from "@root/types/custom-types/Personaliser/Data"

type Props = {
  data: PersonaliserData
  customiserData: CustomiserData
  additional: PersonaliserAdditional
  summary: PersonaliserSummaryData
  matchingLabels?: MatchingLabel[]
  messagingData: MessagingData
  orderSummaryData: OrderSummaryData
  landingPageUrl?: string
}

const Personaliser: React.FC<Props> = ({
  data,
  customiserData,
  additional,
  summary,
  matchingLabels,
  messagingData,
  orderSummaryData,
  landingPageUrl,
}) => {
  const { headerRef } = useAppContext()

  const sectionRef = useRef<HTMLDivElement>(null)

  const [step, setStep] = useState(1)
  const [bundle, setBundle] = useState<Bundle>({
    step1: [],
    step2: undefined,
    step3: undefined,
    step4: [],
    step5: [],
    messaging: {
      sticker: { product: undefined, date: undefined, isSelected: false },
      giftcard: { product: undefined, message: undefined, isSelected: false },
    },
  })

  const [selectedUpsellAddons, setSelectedUpsellAddons] = useState<Addon[]>()
  const [labelLoading, setLabelLoading] = useState(false)

  // When changing steps, scroll to the top of the section
  useEffect(() => {
    setTimeout(() => {
      const top = sectionRef.current ? sectionRef.current.offsetTop - (headerRef?.current?.getBoundingClientRect().height || 0) : 0
      window.scrollTo(0, top)
    }, 10)
  }, [step, sectionRef, headerRef])

  const resetBundle = useCallback(() => {
    setStep(1)
    setBundle({
      step1: [],
      step2: undefined,
      step3: undefined,
      step4: [],
      step5: [],
      messaging: {
        sticker: { product: undefined, date: undefined, isSelected: false },
        giftcard: { product: undefined, message: undefined, isSelected: false },
      },
    })
  }, [setStep, setBundle])

  const visualStepsLength = 5
  const actualStepsLength = visualStepsLength + 2
  const dataKey = `step${step}` as PersonaliserDataKey
  const stepData = Object.keys(data).includes(dataKey.toString()) ? data[dataKey] : undefined

  const defaultErrors: PersonaliserError[] = useMemo(
    () => [
      {
        type: "stepRequired",
        message: stepData?.errorMessage || "",
        active: false,
      },
      {
        type: "dateRequired",
        message: messagingData.sticker.requiredErrorMessage || "",
        active: false,
      },
      {
        type: "messageRequired",
        message: messagingData.giftcard.requiredErrorMessage || "",
        active: false,
      },
    ],
    [messagingData.giftcard.requiredErrorMessage, messagingData.sticker.requiredErrorMessage, stepData?.errorMessage]
  )
  const [errors, setErrors] = useState<PersonaliserError[]>(defaultErrors)

  // Update required error as step changes
  useEffect(() => {
    setErrors(currentErrors => {
      const requiredErrorIndex = currentErrors.findIndex(err => err.type === "stepRequired")
      const newErrors = currentErrors

      if (newErrors[requiredErrorIndex]) {
        newErrors[requiredErrorIndex] = {
          type: "stepRequired",
          message: stepData?.errorMessage || "",
          active: newErrors[requiredErrorIndex].active,
        }
      }

      return newErrors
    })
  }, [step, setErrors, stepData])

  const setError = useCallback(
    (type: PersonaliserErrorType, active: boolean) => {
      setErrors(currentErrors =>
        currentErrors.map(error => {
          if (error.type === type) error.active = active
          return error
        })
      )
    },
    [setErrors]
  )
  const resetErrors = useCallback(() => {
    setErrors(defaultErrors)
  }, [setErrors, defaultErrors])

  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [modalImages, setModalImages] = useState<ModalImage[]>([])
  const [modalProduct, setModalProduct] = useState<NormalisedProduct>()

  const { isLarge } = useMedia()
  const { isOpen, onToggle } = useDisclosure()

  useEffect(() => {
    const { sticker, giftcard } = bundle.messaging
    if ((sticker.isSelected && sticker.date) || !sticker.isSelected) setError("dateRequired", false)
    if ((giftcard.isSelected && giftcard.message) || !giftcard.isSelected) setError("messageRequired", false)
  }, [bundle.messaging, setError])

  // TODO: remove current step param and set as 4. this is only used for removing add ons currently.
  const onRemoveClickHandler: OnRemoveClickHandler = useCallback((id: string, currentStep: number) => {
    const bundleKey = `step${currentStep}` as BundleKey
    setBundle(prevBundle => {
      const remainingAddons: Addon[] = (prevBundle[bundleKey] as Addon[]).filter(addOn => addOn.product.id !== id)

      return {
        ...prevBundle,
        ...{
          [bundleKey]: remainingAddons,
        },
      }
    })
  }, [])

  const onAddProductClickHandler: OnAddProductClickHandler = useCallback(
    (product: NormalisedProduct, qty?: number) => {
      const bundleKey = `step${step}` as BundleKey

      setBundle(prevBundle => {
        const bundleCopy = prevBundle

        if (step === 4) {
          const stepProducts = bundleCopy[bundleKey] as Addon[]
          const quantity = qty || 1
          stepProducts.push({
            product,
            qty: quantity,
          })

          return {
            ...bundleCopy,
            ...{ [bundleKey]: stepProducts },
          }
        } else {
          return {
            ...bundleCopy,
            ...{ [bundleKey]: [product] },
          }
        }
      })
      if (step !== 4 && step !== actualStepsLength) {
        setError("stepRequired", false)
        setStep(step + 1)
      }
    },
    [setBundle, step, setStep, setError, actualStepsLength]
  )

  const onAddToSelectionHandler: OnAddToSelectionHandler = useCallback((product, qty) => {
    const addOn = {
      product: product,
      qty: qty || 1,
    }

    setSelectedUpsellAddons(currentlySelectedAddons => {
      if (!currentlySelectedAddons?.length) return [addOn]
      return [...currentlySelectedAddons, addOn]
    })
  }, [])

  const onRemoveFromSelectionHandler = useCallback((productId: string) => {
    setSelectedUpsellAddons(currentlySelectedAddons => {
      if (!currentlySelectedAddons?.length) return currentlySelectedAddons

      const filteredAddons = currentlySelectedAddons.filter(addOn => addOn.product.id !== productId)
      return [...filteredAddons]
    })
  }, [])

  const sharedProps = {
    bundle: bundle,
    setBundle: setBundle,
    step: step,
    setStep: setStep,
    visualStepsLength: visualStepsLength,
    summary: summary,
    setError: setError,
    errors: errors,
  }

  const paginationProps = {
    ...sharedProps,
    back: additional.navBack,
    next: additional.navNext,
    onToggle: onToggle,
    isOpen: isOpen,
    actualStepsLength: actualStepsLength,
    landingPageUrl: landingPageUrl,
  }

  const customiserProps = {
    ...sharedProps,
    data: customiserData,
    additional: additional,
    onRemoveClickHandler: onRemoveClickHandler,
    resetErrors: resetErrors,
  }

  const gridProps = {
    ...customiserProps,
    title: stepData?.title,
    description: stepData?.description,
    collections: stepData?.collections,
    sanityLabels: stepData?.labels,
    errorMessage: stepData?.errorMessage,
    onAddProductClickHandler: onAddProductClickHandler,
    setIsProductModalOpen: setIsProductModalOpen,
    setIsImageModalOpen: setIsImageModalOpen,
    isImageModalOpen: isImageModalOpen,
    modalImages: modalImages,
    setModalImages: setModalImages,
    modalProduct: modalProduct,
    setModalProduct: setModalProduct,
    labelLoading: labelLoading,
  }

  return (
    <Box as="section" ref={sectionRef}>
      <EmotionGlobal
        styles={`
          #gorgias-chat-container #chat-window {
            @media (min-width: 768px) {
              bottom: 140px !important;
            }
          }
          #gorgias-chat-container #chat-button {
            bottom: 80px !important;
          }
        `}
      />
      <Container maxW="container.4xl" px="0">
        {step === 3 ? (
          <PersonaliserLabelCustomiser {...customiserProps} errors={errors} setLabelLoading={setLabelLoading} labelLoading={labelLoading} />
        ) : step <= visualStepsLength ? (
          <PersonaliserGrid {...gridProps} actualStepsLength={actualStepsLength} />
        ) : null}

        {step === 6 ? (
          <PersonaliserMessaging
            messagingData={messagingData}
            setBundle={setBundle}
            bundle={bundle}
            step={step}
            setStep={setStep}
            visualStepsLength={visualStepsLength}
            summary={summary}
            onRemoveClickHandler={onRemoveClickHandler}
            errors={errors}
            resetErrors={resetErrors}
            labelLoading={labelLoading}
            finalLabelMessage={additional.labelFinaliseMessage}
          />
        ) : null}

        {step === 7 ? (
          <PersonaliserOrderSummary
            data={orderSummaryData}
            additional={additional}
            bundle={bundle}
            setBundle={setBundle}
            resetBundle={resetBundle}
            summary={summary}
            back={additional.navBack}
            newLabel={summary.newLabel}
            edit={summary.edit}
            setStep={setStep}
            step={step}
            setModalProduct={setModalProduct}
            setIsProductModalOpen={setIsProductModalOpen}
            isProductModalOpen={isProductModalOpen}
            onAddProductClickHandler={onAddProductClickHandler}
            onRemoveClickHandler={onRemoveClickHandler}
            selectedUpsellAddons={selectedUpsellAddons}
            onAddToSelectionHandler={onAddToSelectionHandler}
            onRemoveFromSelectionHandler={onRemoveFromSelectionHandler}
            setSelectedUpsellAddons={setSelectedUpsellAddons}
            setIsImageModalOpen={setIsImageModalOpen}
            setModalImages={setModalImages}
            labelLoading={labelLoading}
          />
        ) : null}
      </Container>

      {step <= visualStepsLength || step === actualStepsLength ? (
        <>
          <PersonaliserProductDrawer
            isProductModalOpen={isProductModalOpen}
            setIsProductModalOpen={setIsProductModalOpen}
            modalProduct={modalProduct}
            add={additional.addProduct}
            backToOptions={additional.backToOptions}
            onAddProductClickHandler={onAddProductClickHandler}
            matchingLabels={matchingLabels}
            setIsImageModalOpen={setIsImageModalOpen}
            setModalImages={setModalImages}
            step={step}
            actualStepsLength={actualStepsLength}
            bundle={bundle}
            selectedUpsellAddons={selectedUpsellAddons}
            textSelected={additional.selectedProduct}
            onRemoveClickHandler={onRemoveClickHandler}
            onAddToSelectionHandler={onAddToSelectionHandler}
            onRemoveFromSelectionHandler={onRemoveFromSelectionHandler}
          />
          <PersonaliserImageLightbox
            isImageModalOpen={isImageModalOpen}
            setIsImageModalOpen={setIsImageModalOpen}
            modalImages={modalImages}
            setModalImages={setModalImages}
            isProductModalOpen={isProductModalOpen}
          />
        </>
      ) : null}

      {step !== actualStepsLength ? <PersonaliserStickyPagination {...paginationProps} componentPlacement="footer" /> : null}

      {!isLarge && step !== actualStepsLength ? (
        <>
          <Box
            pos="fixed"
            top="0"
            left="0"
            w="full"
            h="full"
            zIndex="overlay"
            bg="background.overlay"
            opacity={isOpen ? "1" : "0"}
            pointerEvents={isOpen ? "initial" : "none"}
            transition="opacity 0.2s ease-in-out 0.1s"
            onClick={onToggle}
          />
          <Fade in={isOpen} transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }} unmountOnExit>
            <PersonaliserSummaryWidget
              bundle={bundle}
              setBundle={setBundle}
              step={step}
              setStep={setStep}
              visualStepsLength={visualStepsLength}
              summary={summary}
              isMobile={true}
              onToggle={onToggle}
              onRemoveClickHandler={onRemoveClickHandler}
              resetErrors={resetErrors}
              labelLoading={labelLoading}
              finalLabelMessage={additional.labelFinaliseMessage}
            />
          </Fade>
        </>
      ) : null}
    </Box>
  )
}

export default React.memo(Personaliser)

import React, { useCallback, useMemo } from "react"
import {
  AspectRatio,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react"

import { useShopifyProduct, useShopify } from "@app/hooks/useShopify"
import { useImage } from "@app/hooks/useImage"
import { Icon } from "@app/components/Icon"
import PersonaliserProductPrice from "@app/components/Personaliser/PersonaliserProductPrice"
import ProductLabel from "@app/components/Product/ProductLabel"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import {
  OnAddProductClickHandler,
  MatchingLabel,
  ModalImage,
  Bundle,
  Addon,
  OnRemoveClickHandler,
  OnAddToSelectionHandler,
  OnRemoveFromSelectionHandler,
} from "@root/types/custom-types/Personaliser/Data"

type Props = {
  isProductModalOpen: boolean
  setIsProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalProduct: NormalisedProduct | undefined
  add: string
  backToOptions: string
  onAddProductClickHandler: OnAddProductClickHandler
  matchingLabels?: MatchingLabel[]
  setIsImageModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setModalImages: React.Dispatch<React.SetStateAction<ModalImage[]>>
  step: number
  actualStepsLength: number
  bundle: Bundle
  selectedUpsellAddons: Addon[] | undefined
  textSelected: string
  onRemoveClickHandler: OnRemoveClickHandler
  onAddToSelectionHandler: OnAddToSelectionHandler
  onRemoveFromSelectionHandler: OnRemoveFromSelectionHandler
}

const PersonaliserProductDrawer: React.FC<Props> = ({
  isProductModalOpen,
  setIsProductModalOpen,
  modalProduct,
  add,
  backToOptions,
  onAddProductClickHandler,
  matchingLabels,
  setIsImageModalOpen,
  setModalImages,
  step,
  actualStepsLength,
  bundle,
  selectedUpsellAddons,
  textSelected,
  onRemoveClickHandler,
  onAddToSelectionHandler,
  onRemoveFromSelectionHandler,
}) => {
  const { getGatsbyImage } = useImage()
  const { getProductTagsValues } = useShopifyProduct()
  const { getFirstAvailableVariant } = useShopify()

  const availableVariant = useMemo(
    () => (modalProduct ? getFirstAvailableVariant(modalProduct) : undefined),
    [getFirstAvailableVariant, modalProduct]
  )

  const onCloseHandler = useCallback(() => setIsProductModalOpen(false), [setIsProductModalOpen])

  const onAddHandler = useCallback(() => {
    if (!modalProduct) return null
    if (step === actualStepsLength) {
      onAddToSelectionHandler(modalProduct, 1)
    } else {
      onAddProductClickHandler(modalProduct)
    }

    onCloseHandler()
  }, [onAddProductClickHandler, onCloseHandler, step, actualStepsLength, modalProduct, onAddToSelectionHandler])

  const onRemoveHandler = useCallback(() => {
    if (!modalProduct) return null
    if (step === actualStepsLength) {
      onRemoveFromSelectionHandler(modalProduct.id)
    } else {
      onRemoveClickHandler(modalProduct.id, 4)
    }
    onCloseHandler()
  }, [onRemoveClickHandler, onCloseHandler, modalProduct, onRemoveFromSelectionHandler, step, actualStepsLength])

  const addOnData = bundle["step4"]
  const inBundle =
    step === 7
      ? !!selectedUpsellAddons?.some(addOn => addOn.product.id === modalProduct?.id)
      : !!addOnData?.some(addOn => addOn.product.id === modalProduct?.id)

  if (!modalProduct) return null

  const image = getGatsbyImage(modalProduct.images)[0]
  const labels = getProductTagsValues("label:", modalProduct.tags)

  const onZoomHandler = () => {
    setModalImages(modalProduct.images)
    setIsImageModalOpen(true)
  }

  return (
    <Drawer isOpen={isProductModalOpen} onClose={onCloseHandler}>
      <DrawerOverlay />

      <DrawerContent maxW={{ base: "80", lg: "100" }}>
        <IconButton
          onClick={onCloseHandler}
          variant="unstyled"
          icon={<Icon name="ui/close" width="24px" height="24px" />}
          aria-label={"Close Drawer"}
          pos="absolute"
          top="4"
          right="4"
          bg="background.white"
          zIndex="overlay"
          d="inline-flex"
          justifyContent="center"
          alignItems="center"
          w="10"
          h="10"
          borderRadius="full"
          boxShadow="subtleBottomGlow"
          _focus={{ boxShadow: "subtleBottomGlow" }}
        />

        <IconButton
          onClick={onZoomHandler}
          variant="unstyled"
          icon={<Icon name="ui/zoom" width="24px" height="24px" />}
          aria-label={"Close Drawer"}
          pos="absolute"
          top="4"
          left="4"
          d="inline-flex"
          color="brand.primary"
          bg="background.white"
          zIndex="overlay"
          justifyContent="center"
          alignItems="center"
          w="10"
          h="10"
          borderRadius="full"
          boxShadow="subtleBottomGlow"
          _focus={{ boxShadow: "subtleBottomGlow" }}
        />

        <DrawerBody p="6">
          <AspectRatio ratio={1 / 1} mb={{ base: "5", lg: "6" }}>
            <Image {...image} />
          </AspectRatio>

          {labels.length ? (
            <Flex gap="2" mb="4">
              {labels.map(label => (
                <ProductLabel key={label.toLowerCase()} label={label} matchingLabels={matchingLabels} />
              ))}
            </Flex>
          ) : null}

          <VStack spacing={{ base: "0", lg: "1" }} alignItems="flex-start" mb={{ base: "5", lg: "6" }}>
            <Heading size="h5" color="typography.headlines900">
              {modalProduct.title}
            </Heading>

            {modalProduct.vendor ? <Text size="largeParagraph">{modalProduct.vendor}</Text> : null}

            {availableVariant ? <PersonaliserProductPrice variant={availableVariant} type="modal" /> : null}
          </VStack>

          {modalProduct.descriptionHtml ? (
            <Text
              as={Box}
              size="largeParagraph"
              dangerouslySetInnerHTML={{ __html: modalProduct.descriptionHtml }}
              mb={{ base: "5", lg: "6" }}
            />
          ) : null}

          {(step === 4 || step === 7) && inBundle ? (
            <Button w="full" onClick={onRemoveHandler} mb={{ base: "5", lg: "6" }}>
              {textSelected}
            </Button>
          ) : (
            <Button w="full" onClick={onAddHandler} mb={{ base: "5", lg: "6" }}>
              {add}
            </Button>
          )}

          <Text as="button" onClick={onCloseHandler} size="textLinkMedium">
            {backToOptions}
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(PersonaliserProductDrawer)

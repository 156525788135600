import React from "react"
import { Box, Flex, Button } from "@chakra-ui/react"

export type StickyPaginationButtonsProps = {
  PersonaliserStickyPaginationData: any
}

const PersonaliserStickyPaginationButtons: React.FC<StickyPaginationButtonsProps> = ({ PersonaliserStickyPaginationData }) => {
  const { onPrevClickHandler, onNextClickHandler, step } = PersonaliserStickyPaginationData
  return (
    <Box>
      <Flex justifyContent={`${step === 1 ? "flex-end" : "space-between"}`}>
        {step !== 1 && (
          <Button onClick={onPrevClickHandler} variant="solidSecondary">
            Back
          </Button>
        )}

        <Button onClick={onNextClickHandler} marginLeft="2.5px" variant="solidSecondary">
          Next
        </Button>
      </Flex>
    </Box>
  )
}

export default React.memo(PersonaliserStickyPaginationButtons)

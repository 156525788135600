import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Grid, Heading, IconButton, Modal, ModalBody, ModalContent, ModalOverlay, ModalFooter, Skeleton, Text } from "@chakra-ui/react"

import { useShopify } from "@app/hooks/useShopify"
import { Icon } from "@app/components/Icon"
import PersonaliserProductItem from "@app/components/Personaliser/PersonaliserProductItem"
import ButtonWithIcon from "@app/components/Input/ButtonWithIcon"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import {
  Addon,
  Bundle,
  OnAddToSelectionHandler,
  OnRemoveFromSelectionHandler,
  OrderSummaryUpsellData,
  PersonaliserAdditional,
} from "@root/types/custom-types/Personaliser/Data"

type Props = {
  data: OrderSummaryUpsellData
  additional: PersonaliserAdditional
  setShowUpsellModal: React.Dispatch<React.SetStateAction<boolean>>
  showUpsellModal: boolean
  setIsProductModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isProductModalOpen: boolean
  bundle: Bundle
  setBundle: React.Dispatch<React.SetStateAction<Bundle>>
  setModalProduct: React.Dispatch<React.SetStateAction<NormalisedProduct | undefined>>
  step: number
  selectedUpsellAddons: Addon[]
  setSelectedUpsellAddons: React.Dispatch<React.SetStateAction<Addon[] | undefined>>
  onAddToSelectionHandler: OnAddToSelectionHandler
  onRemoveFromSelectionHandler: OnRemoveFromSelectionHandler
}

const PersonaliserUpsellsWidget: React.FC<Props> = ({
  data,
  additional,
  setShowUpsellModal,
  showUpsellModal,
  setIsProductModalOpen,
  bundle,
  setBundle,
  setModalProduct,
  step,
  selectedUpsellAddons,
  setSelectedUpsellAddons,
  onAddToSelectionHandler,
  onRemoveFromSelectionHandler,
}) => {
  const { getProducts } = useShopify()

  const [products, setProducts] = useState<NormalisedProduct[]>()

  const { title, description, applyButtonLabel, sanityProducts } = data

  const onCloseHandler = useCallback(() => setShowUpsellModal(false), [setShowUpsellModal])

  const onApplyHandler = useCallback(() => {
    if (!selectedUpsellAddons?.length) return

    setBundle(prevBundle => {
      const currentAddOns = prevBundle.step4 || []

      return {
        ...prevBundle,
        ...{ ["step4"]: [...currentAddOns, ...selectedUpsellAddons] },
      }
    })

    setShowUpsellModal(false)
  }, [selectedUpsellAddons, setBundle, setShowUpsellModal])

  useEffect(() => {
    if (showUpsellModal !== true) setSelectedUpsellAddons([])
  }, [showUpsellModal, setSelectedUpsellAddons])

  const fetchData = useMemo(
    () => async () => {
      if (!sanityProducts) return

      const handles = sanityProducts.map(sProduct => sProduct.shopify?.handle).filter(h => h !== undefined) as string[]
      const productsResults = await getProducts({
        firstVariants: 1,
        firstImages: 2,
        firstMedia: 2,
        handles: handles,
      })

      if (productsResults.length) setProducts(productsResults)
    },
    [sanityProducts, getProducts, setProducts]
  )

  // intentionally only run once at first render
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanityProducts])

  return (
    <Modal onClose={onCloseHandler} isOpen={showUpsellModal} size="upsell" isCentered>
      <ModalOverlay bg="background.overlay" />

      <ModalContent>
        <ModalBody p="0">
          <IconButton
            onClick={onCloseHandler}
            variant="unstyled"
            icon={<Icon name="ui/close" width="24px" height="24px" />}
            aria-label={"Close Drawer"}
            pos="absolute"
            top="4"
            right="4"
            bg="background.white"
            zIndex="overlay"
            d="inline-flex"
            justifyContent="center"
            alignItems="center"
            w="10"
            h="10"
            borderRadius="full"
            boxShadow="subtleBottomGlow"
            _focus={{ boxShadow: "subtleBottomGlow" }}
          />

          <Box px={{ base: "6", lg: "12" }} pt={{ base: "6", lg: "12" }} pb={{ base: "4", lg: "6" }} textAlign="center">
            {title ? (
              <Heading size="h4" color="typography.headlines900" maxW="150" mx="auto">
                {title}
              </Heading>
            ) : null}

            {description ? (
              <Text size="largeParagraph" maxW="150" mx="auto">
                {description}
              </Text>
            ) : null}
          </Box>

          <Grid
            gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
            gap={{ base: "4", lg: "5" }}
            pt={{ base: "4", md: "8", lg: "6" }}
            pb={{ base: "20", md: "8", lg: "6" }}
            mx={{ base: "4", md: "8", lg: "12" }}
            borderTop={{ lg: "1px solid" }}
            borderColor={{ lg: "border.default" }}
          >
            {products && products.length
              ? products.map((product, index) => (
                  <PersonaliserProductItem
                    key={`upsellItem-${index}`}
                    item={product}
                    textAdd={additional.addProduct}
                    textAbout={additional.aboutProduct}
                    textOnSale={additional.onSale}
                    textSelected={additional.selectedProduct}
                    bundle={bundle}
                    selectedAddons={selectedUpsellAddons}
                    step={step}
                    setIsProductModalOpen={setIsProductModalOpen}
                    setModalProduct={setModalProduct}
                    onAddProductClickHandler={onAddToSelectionHandler}
                    onRemoveClickHandler={onRemoveFromSelectionHandler}
                  />
                ))
              : [...Array(4)].map((i, index) => <Skeleton key={index} isLoaded={false} w="full" pb="calc(100% + 188px)" />)}
          </Grid>
        </ModalBody>

        <ModalFooter px={0} display="flex" flexDirection="column" py={{ base: 4, md: 0 }}>
          <ButtonWithIcon
            onClick={onApplyHandler}
            variant="solidSecondary"
            iconName="arrows/chevron-right"
            w={{ base: "calc(100% - 32px)", md: "full" }}
            zIndex="popover"
            boxShadow={{ base: "subtleBottomGlow", md: "unset" }}
            borderRadius={{ base: "xl", md: "none" }}
            disabled={!selectedUpsellAddons?.length}
          >
            <Text size="labels">{applyButtonLabel}</Text>
          </ButtonWithIcon>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(PersonaliserUpsellsWidget)

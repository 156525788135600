import React, { useCallback, useMemo } from "react"
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Flex, StackItem, Text, VStack } from "@chakra-ui/react"

import { useShopify } from "@app/hooks/useShopify"
import PersonaliserSummaryItem from "@app/components/Personaliser/PersonaliserSummaryItem"
import { Icon } from "@app/components/Icon"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import {
  Addon,
  Bundle,
  OnRemoveClickHandler,
  PersonaliserSummaryData,
  ResetErrors,
  SummaryStepKey,
} from "@root/types/custom-types/Personaliser/Data"

type Props = {
  bundle: Bundle
  setBundle: React.Dispatch<React.SetStateAction<Bundle>>
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  visualStepsLength: number
  summary: PersonaliserSummaryData
  isMobile?: boolean
  onToggle?: () => void
  onRemoveClickHandler: OnRemoveClickHandler
  resetErrors: ResetErrors
  labelLoading: boolean
  finalLabelMessage: string
}

const PersonaliserSummaryWidget: React.FC<Props> = ({
  bundle,
  step,
  setStep,
  visualStepsLength,
  summary,
  isMobile,
  onToggle,
  onRemoveClickHandler,
  resetErrors,
  labelLoading,
  finalLabelMessage,
}) => {
  const { summaryTitle, summaryTotal } = summary
  const { formatMoney, getFirstAvailableVariant } = useShopify()

  const stepsWithData: number[] = []
  Object.values(bundle).forEach((item, index) => {
    if ((item && ((Array.isArray(item) && item.length) || "categories" in item)) || (item && "scene" in item)) {
      stepsWithData.push(index)
    }
  })

  const stepIndexes = stepsWithData
    .map((i: number) => {
      if (i === 2) return 0
      if (i >= 3) return i - 1
      return i
    })
    .filter(i => i !== undefined)

  const stepNumbers = [...Array(visualStepsLength).keys()]

  const accordionStep = step - 1 >= 2 ? step - 2 : step - 1
  const accordionIndexes = [...stepIndexes]
  if (!accordionIndexes.includes(accordionStep)) accordionIndexes.push(accordionStep)

  const total = useMemo(() => {
    return Object.values(bundle).reduce((stepsTotal, step) => {
      if (!bundle.step3?.labelProduct && step && "categories" in step) {
        const availableVariant = getFirstAvailableVariant(step.product)
        return (stepsTotal += parseFloat(availableVariant?.priceV2 as unknown as string) || 0) // For whatever reason the Sanity product has a different priceV2 format?
      } else if (step && "labelProduct" in step) {
        const availableVariant = getFirstAvailableVariant(step.labelProduct)
        return (stepsTotal += parseFloat(availableVariant?.priceV2 as unknown as string) || 0)
      } else if (Array.isArray(step) && step.length) {
        if ("id" in step[0]) {
          return (stepsTotal += (step as NormalisedProduct[]).reduce((acc, product) => {
            const availableVariant = getFirstAvailableVariant(product)
            if (!availableVariant) return acc

            return (acc += parseFloat(availableVariant.priceV2.amount))
          }, 0))
        } else {
          return (stepsTotal += (step as Addon[]).reduce((acc, addOn) => {
            const availableVariant = getFirstAvailableVariant(addOn.product)
            if (!availableVariant) return acc

            return (acc += parseFloat(availableVariant.priceV2.amount) * addOn.qty)
          }, 0))
        }
      } else {
        return stepsTotal
      }
    }, 0)
  }, [bundle, getFirstAvailableVariant])

  const compareAtTotal = useMemo(() => {
    return Object.values(bundle).reduce((stepsTotal, step) => {
      if (!bundle.step3?.labelProduct && step && "categories" in step) {
        const availableVariant = getFirstAvailableVariant(step.product)
        const amount = availableVariant?.compareAtPriceV2
          ? parseFloat(availableVariant?.compareAtPriceV2 as unknown as string)
          : parseFloat(availableVariant?.priceV2 as unknown as string)
        return (stepsTotal += amount || 0) // For whatever reason the Sanity product has a different priceV2 format?
      } else if (step && "labelProduct" in step) {
        const availableVariant = getFirstAvailableVariant(step.labelProduct)
        const amount = availableVariant?.compareAtPriceV2
          ? parseFloat(availableVariant?.compareAtPriceV2 as unknown as string)
          : parseFloat(availableVariant?.priceV2 as unknown as string)
        return (stepsTotal += amount || 0)
      } else if (Array.isArray(step) && step.length) {
        if ("id" in step[0]) {
          return (stepsTotal += (step as NormalisedProduct[]).reduce((acc, product) => {
            const availableVariant = getFirstAvailableVariant(product)
            if (!availableVariant) return acc
            const amount = availableVariant.compareAtPriceV2?.amount
              ? parseFloat(availableVariant.compareAtPriceV2?.amount)
              : parseFloat(availableVariant.priceV2.amount)
            return (acc += amount)
          }, 0))
        } else {
          return (stepsTotal += (step as Addon[]).reduce((acc, addOn) => {
            const availableVariant = getFirstAvailableVariant(addOn.product)
            if (!availableVariant) return acc
            const amount = availableVariant.compareAtPriceV2?.amount
              ? parseFloat(availableVariant.compareAtPriceV2?.amount)
              : parseFloat(availableVariant.priceV2.amount)
            return (acc += amount * addOn.qty)
          }, 0))
        }
      } else {
        return stepsTotal
      }
    }, 0)
  }, [bundle, getFirstAvailableVariant])

  const showSummary = useMemo(
    () => bundle.step1?.length && bundle.step3?.labelProduct && bundle.step5?.length,
    [bundle.step1, bundle.step3, bundle.step5]
  )
  const goToSummaryHandler = useCallback(() => setStep(7), [setStep])

  const onSale = compareAtTotal > total

  return (
    <Box
      pos={isMobile ? "fixed" : "initial"}
      bottom={isMobile ? "0" : "initial"}
      left={isMobile ? "0" : "initial"}
      w={isMobile ? "full" : "initial"}
      p={isMobile ? "4" : "initial"}
      zIndex={isMobile ? "popover" : "auto"}
      maxH={isMobile ? "100%" : "initial"}
      overflow={isMobile ? "auto" : "initial"}
    >
      <Box
        border="1px solid"
        borderColor="border.light"
        borderRadius="xl"
        overflow="hidden"
        boxShadow="subtleBottomGlow"
        bg="background.white"
      >
        <Flex
          as="header"
          onClick={isMobile ? onToggle : undefined}
          cursor={isMobile ? "pointer" : "initial"}
          justifyContent="center"
          alignItems="center"
          gap="1"
          p="4"
          bg="brand.secondaryFocus"
          color="background.white"
        >
          <Box as="span" w="5" h="5">
            <Icon name="arrows/chevron-down" width="100%" height="100%" />
          </Box>

          <Text size="mediumLabel" fontWeight="bold" textTransform="uppercase">
            {summaryTitle}
          </Text>
        </Flex>

        <Accordion key={step} allowMultiple defaultIndex={accordionIndexes} px="4">
          {stepNumbers.map((stepNumber: number) => {
            /**
             * Step 3 is the label customiser and has no data to show, the label
             * is shown as part of step 2
             */
            stepNumber++
            if (stepNumber === 3) return null

            const stepLabelData = summary[`step${stepNumber}` as SummaryStepKey]
            const onNextClickHandler = () => {
              setStep(step => step + 1)
            }
            const onPrevClickHandler = () => {
              setStep(step => step - 1)
            }

            return (
              <AccordionItem key={stepNumber} borderColor="border.default" _first={{ borderTop: "0" }} _last={{ borderBottom: "0" }}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      d="flex"
                      justifyContent="space-between"
                      _hover={{ background: "none" }}
                      _focus={{ boxShadow: "none" }}
                      py="5"
                      px="0"
                    >
                      <Text size="smSemiLarge">{stepLabelData.label}</Text>

                      <Box as="span" w="5" h="5">
                        <Icon name={isExpanded ? "ui/minus" : "ui/plus"} width="100%" height="100%" />
                      </Box>
                    </AccordionButton>

                    <AccordionPanel pt="0" px="0" pb="5">
                      <PersonaliserSummaryItem
                        placeholder={stepLabelData.placeholder}
                        bundle={bundle}
                        onRemoveClickHandler={onRemoveClickHandler}
                        stepNumber={stepNumber}
                        setStep={setStep}
                        edit={summary.edit}
                        newLabel={summary.newLabel}
                        remove={summary.remove}
                        resetErrors={resetErrors}
                        labelLoading={labelLoading}
                        finalLabelMessage={finalLabelMessage}
                        onToggle={onToggle}
                        stepLabelData={stepLabelData}
                        onNextClickHandler={onNextClickHandler}
                        onPrevClickHandler={onPrevClickHandler}
                        step={step}
                      />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            )
          })}
        </Accordion>

        <Box as="footer">
          <Box bg="background.grey100" py="4" px="4">
            <Flex justifyContent="space-between" mb="1.5">
              <Text size="smSemiLarge">{summaryTotal}</Text>
              <Flex alignItems="baseline" gap="1.5">
                {onSale ? (
                  <Text as="s" size="smSemiSmall">
                    {formatMoney(compareAtTotal)}
                  </Text>
                ) : null}
                <Text color={onSale ? "functional.errorContent" : undefined} size="smSemiLarge">
                  {formatMoney(total)}
                </Text>
              </Flex>
            </Flex>

            <VStack spacing="1.5" alignItems="flex-start">
              {[1, 2].map(i => {
                const label = summary[`summaryTotalLine${i}` as "summaryTotalLine1" | "summaryTotalLine2"]
                if (!label) return null

                return (
                  <StackItem key={label.toLowerCase()} d="flex" alignItems="center" gap="1.5">
                    <Box as="span" w="3.5" h="3.5">
                      <Icon name="ui/check-circle" width="100%" height="100%" />
                    </Box>

                    <Text size="smRegular">{label}</Text>
                  </StackItem>
                )
              })}
            </VStack>
          </Box>

          {showSummary ? (
            <Box>
              <Button onClick={goToSummaryHandler} w="full" borderRadius="0">
                {summary.summaryButtonLabel}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(PersonaliserSummaryWidget)

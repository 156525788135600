import React, { useCallback, useMemo } from "react"
import { Box, Button, Progress, Text, VStack } from "@chakra-ui/react"
import { useShopify } from "@app/hooks/useShopify"

type Props = {
  freeShippingThreshold?: number
  freeShippingProgress: string
  freeShippingReceived: string
  addToCart: string
  orderTotal: number
  upsellCtaLabel: string
  setShowUpsellModal: React.Dispatch<React.SetStateAction<boolean>>
}

const PersonaliserShippingWidget: React.FC<Props> = ({
  freeShippingThreshold,
  orderTotal,
  freeShippingProgress,
  freeShippingReceived,
  addToCart,
  upsellCtaLabel,
  setShowUpsellModal,
}) => {
  const { formatMoney } = useShopify()

  const isAboveThreshold = useMemo(
    () => !!(freeShippingThreshold && orderTotal >= freeShippingThreshold),
    [freeShippingThreshold, orderTotal]
  )
  const remaining = useMemo(() => (freeShippingThreshold ? freeShippingThreshold - orderTotal : 0), [freeShippingThreshold, orderTotal])
  const shippingText = useMemo(
    () => (!isAboveThreshold ? freeShippingProgress.replace("%amount%", formatMoney(remaining)) : freeShippingReceived),
    [isAboveThreshold, freeShippingProgress, remaining, freeShippingReceived, formatMoney]
  )
  const progresPercentage = useMemo(
    () => (freeShippingThreshold && !isAboveThreshold ? Math.round((orderTotal / freeShippingThreshold) * 100) : 100),
    [freeShippingThreshold, isAboveThreshold, orderTotal]
  )

  const onUpsellClickHandler = useCallback(() => setShowUpsellModal(true), [setShowUpsellModal])

  return freeShippingThreshold || addToCart ? (
    <VStack spacing={{ base: "4" }} py="5" px={{ base: "5", lg: "6" }} boxShadow="subtleBottomGlow" borderRadius="xl">
      {freeShippingThreshold ? (
        <Box>
          <Text size="smSemiLarge" mb="2">
            {shippingText}
          </Text>

          <Progress value={progresPercentage} />
        </Box>
      ) : null}

      {upsellCtaLabel ? (
        <Button variant="solidSecondary" w="full" onClick={onUpsellClickHandler}>
          {upsellCtaLabel}
        </Button>
      ) : null}
    </VStack>
  ) : null
}

export default React.memo(PersonaliserShippingWidget)

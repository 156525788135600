import React, { useCallback, useMemo } from "react"
import { Box, Grid } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"

import PersonaliserSummaryWidget from "@app/components/Personaliser/PersonaliserSummaryWidget"
import PersonaliserHeaderPagination from "@app/components/Personaliser/PersonaliserHeaderPagination"
import CustomAlert from "@app/components/CustomAlert"
import Customiser from "@app/components/Personaliser/Customiser/Customiser"
import {
  Bundle,
  CustomiserData,
  PersonaliserAdditional,
  PersonaliserSummaryData,
  OnRemoveClickHandler,
  ResetErrors,
  PersonaliserError,
  SetPersonliaserError,
  CustomLabel,
} from "@root/types/custom-types/Personaliser/Data"

type Props = {
  bundle: Bundle
  setBundle: React.Dispatch<React.SetStateAction<Bundle>>
  step: number
  visualStepsLength: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  data: CustomiserData
  additional: PersonaliserAdditional
  summary: PersonaliserSummaryData
  onRemoveClickHandler: OnRemoveClickHandler
  resetErrors: ResetErrors
  errors: PersonaliserError[]
  setError: SetPersonliaserError
  setLabelLoading: React.Dispatch<React.SetStateAction<boolean>>
  labelLoading: boolean
}

const PersonaliserLabelCustomiser: React.FC<Props> = ({
  bundle,
  setBundle,
  step,
  setStep,
  visualStepsLength,
  data,
  additional,
  summary,
  onRemoveClickHandler,
  resetErrors,
  errors,
  setError,
  setLabelLoading,
  labelLoading,
}) => {
  const {
    helpers: { isBrowser, ErrorBoundary },
  } = useCore()
  const { isLarge } = useMedia()
  const paginationText = useMemo(
    () => additional.pagination.replace("%step%", step.toString()).replace("%stepsTotal%", visualStepsLength.toString()),
    [additional.pagination, step, visualStepsLength]
  )

  const setPersonaliserBundle = useCallback(
    (data: CustomLabel) => {
      setBundle(prevBundle => {
        return {
          ...prevBundle,
          step3: {
            ...data,
          },
        }
      })
    },
    [setBundle]
  )

  const onConfirmCallback = useCallback(() => {
    setStep(step => step + 1)
    setError("stepRequired", false)
  }, [setStep, setError])

  const stepError = useMemo(() => errors && errors.find(e => e.type === "stepRequired"), [errors])
  const gridProps = {
    bundle: bundle,
    setBundle: setBundle,
    setStep: setStep,
    setError: setError,
    step: step,
  }
  return (
    <Grid
      templateColumns={{ base: "1fr", lg: "1fr 2fr" }}
      gap={{ lg: "15" }}
      pt={{ lg: "12" }}
      px={{ lg: "20" }}
      pb={{ lg: "22.5" }}
      bg={{ base: "background.beige", lg: "background.white" }}
    >
      {isLarge ? (
        <Box gridArea={{ lg: "1/1/2/2" }}>
          <PersonaliserSummaryWidget
            bundle={bundle}
            setBundle={setBundle}
            step={step}
            setStep={setStep}
            visualStepsLength={visualStepsLength}
            summary={summary}
            onRemoveClickHandler={onRemoveClickHandler}
            resetErrors={resetErrors}
            labelLoading={labelLoading}
            finalLabelMessage={additional.labelFinaliseMessage}
          />
        </Box>
      ) : null}

      <Box gridArea={{ lg: "1/2/2/3" }} px={{ base: "4", lg: "0" }} pb={{ base: "4", lg: "0" }}>
        <PersonaliserHeaderPagination
          {...gridProps}
          title={data.title}
          description={data.description}
          paginationText={paginationText}
          isLarge={isLarge}
        />

        {stepError?.active && stepError.message ? (
          <Box px={{ base: "4", md: "8", lg: "0" }} pt={{ base: "4", lg: "0" }} mb={{ lg: "6" }}>
            <CustomAlert status="error" title={additional.errorPrefix} withoutIcon={true}>
              {stepError.message}
            </CustomAlert>
          </Box>
        ) : null}

        {isBrowser ? (
          <ErrorBoundary>
            <Customiser
              bundle={bundle}
              setBundle={setPersonaliserBundle}
              data={data}
              setStep={setStep}
              setLabelLoading={setLabelLoading}
              onConfirmCb={onConfirmCallback}
            />
          </ErrorBoundary>
        ) : null}
      </Box>
    </Grid>
  )
}

export default React.memo(PersonaliserLabelCustomiser)
